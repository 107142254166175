import { db } from '../firebase/firebase';
import { collection, getDocs, getDoc, doc, updateDoc, addDoc, setDoc, deleteDoc, Timestamp, where, query, onSnapshot } from 'firebase/firestore';
import { PinManager } from '../models';
import { IProject, IPin } from '../types';
import { getFirestore } from 'firebase/firestore';

export const ProjectManager = {
  observeProjects: async (): Promise<IProject[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Projects'));
      const projects: IProject[] = [];
      querySnapshot.forEach((docSnapshot) => {
        const project = { key: docSnapshot.id, ...docSnapshot.data() } as IProject;
        // Removed the isAvailable check to include all projects
        projects.push(project);
      });
      return projects;
    } catch (error) {
      console.log("Error getting projects:", error);
      return [];
    }
  },

  getProject: async (project_id: string): Promise<IProject | null> => {
    try {
      if (!project_id) {
        throw new Error('Invalid project_id');
      }
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        console.log("No project found");
        return null;
      }
      return { key: projectDoc.id, ...projectDoc.data() } as IProject;
    } catch (error) {
      console.log("Error fetching project:", error);
      return null;
    }
  },

  getProjectIcon: async (project_id: string): Promise<string | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      if (project && project.icon) {
        return project.icon;
      }
      return null;
    } catch (error) {
      console.log("Error fetching project icon:", error);
      return null;
    }
  },

  checkPinEnableForQRCodeOrNot: async (pin: any): Promise<boolean> => {
    try {
      const project = await ProjectManager.getProject(pin.project);
      if (project && project.isQREnabled && pin.pinType === "qrCode") {
        return true;
      }
      return false;
    } catch (error) {
      console.log("Error checking QR code enablement:", error);
      return false;
    }
  },

  getProjectPins: async (project_id: string): Promise<IPin[]> => {
    try {
      // Fetch all available pins
      const allPins = await PinManager.observePins();

      // Filter pins that belong to the given project
      const projectPins = allPins.filter(pin => pin.project === project_id);
      return projectPins;
    } catch (error) {
      console.log(`Error fetching pins for project ${project_id}:`, error);
      return [];
    }
  },

  updateProjectAudioSize: async (project_id: string, additionalSize: number): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
  
      if (!projectDoc.exists()) {
        console.log("No project found to update audio size");
        return;
      }
  
      const projectData = projectDoc.data() as IProject;
      const currentAudioSize = projectData.audioSize || 0;
      const newAudioSize = currentAudioSize + additionalSize;
  
      // Update the project's audioSize with the new total
      await updateDoc(projectDocRef, {
        audioSize: newAudioSize
      });
  
    } catch (error) {
      console.log("Error updating project audio size:", error);
    }
  },

  getSubjectTagsForProject: async (project_id: string): Promise<string[]> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (projectDoc.exists()) {
        const projectData = projectDoc.data() as IProject;
        return projectData.tags?.subjectTag || []; 
      }
      return [];
    } catch (error) {
      console.log("Error fetching subject tags:", error);
      return [];
    }
  },

  addSubjectTagToProject: async (project_id: string, tagName: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        console.log("No project found to add subject tag");
        return;
      }
      const projectData = projectDoc.data() as IProject;
      const subjectTags = new Set(projectData.tags?.subjectTag || []);
      subjectTags.add(tagName);
  
      await updateDoc(projectDocRef, {
        'tags.subjectTag': Array.from(subjectTags)
      });
    } catch (error) {
      console.log("Error adding subject tag to project:", error);
    }
  },
  
  // Remove a tag from the project's subjectTags array
  removeSubjectTagFromProject: async (project_id: string, tagName: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
      if (!projectDoc.exists()) {
        console.log("No project found to remove subject tag");
        return;
      }
      const projectData = projectDoc.data() as IProject;
      const subjectTags = new Set(projectData.tags?.subjectTag || []);
      if (subjectTags.has(tagName)) {
        subjectTags.delete(tagName);
        await updateDoc(projectDocRef, {
          'tags.subjectTag': Array.from(subjectTags)
        });
        console.log(`Removed subject tag ${tagName} from project ${project_id}`);
      }
    } catch (error) {
      console.log("Error removing subject tag from project:", error);
    }
  },

  updateContentTagForProject: async (project_id: string, contentTag: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      const projectDoc = await getDoc(projectDocRef);
  
      if (!projectDoc.exists()) {
        console.log("No project found to update content tag");
        return;
      }
  
      // Update the project's contentTag
      await updateDoc(projectDocRef, {
        'tags.contentTag': contentTag
      });
  
    } catch (error) {
      console.log("Error updating content tag for project:", error);
    }
  },

  createProject: async (project_id: string, projectData: IProject): Promise<void> => {
    try {
      // Create a reference to the new project document with the specified project_id
      const projectDocRef = doc(collection(db, 'Projects'), project_id);

      // Set the new project document data with the project_id as its document ID
      await setDoc(projectDocRef, {
        ...projectData,
        audioSize: 0, // Initialize audioSize to 0
        isResponseEnabled: projectData.isResponseEnabled || false, 
      });

    } catch (error) {
      console.error("Error creating new project with key " + project_id + ":", error);
    }
  },
  updateProject: async (project_id: string, updatedProjectData: Partial<IProject>): Promise<IProject | null> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
  
      await updateDoc(projectDocRef, {
        ...updatedProjectData,
        // Only update fields that are present in updatedProjectData
      });
  
      // Fetch and return the updated project data
      return await ProjectManager.getProject(project_id);
    } catch (error) {
      console.error(`Error updating project with key ${project_id}:`, error);
      return null;
    }
  },
  deleteProject: async (project_id: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await deleteDoc(projectDocRef);
    } catch (error) {
      console.error(`Error deleting project with key ${project_id}:`, error);
    }
  },


  updateProjectAvailability: async (project_id: string, isAvailable: boolean): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      // Update the isAvailable property in Firestore
      await updateDoc(projectDocRef, { isAvailable });
    } catch (error) {
      console.error(`Error updating project availability for project ${project_id}:`, error);
    }
  },
  getProjectConfig: async (project_id: string): Promise<any> => {
    const projectDocRef = doc(db, 'Projects', project_id);
    const projectDoc = await getDoc(projectDocRef);
    if (projectDoc.exists()) {
      const projectData = projectDoc.data();
      return projectData.config || { locationInfor: true, authorInfo: true, recordingInfo: true, uploadFields: true, password: '' }; // Include default password
    }
    return null;
  },
  
  updateProjectConfig: async (project_id: string, config: any): Promise<void> => {
    const projectDocRef = doc(db, 'Projects', project_id);
    await updateDoc(projectDocRef, { config });
  },

  setTransitAlbumKey: async (project_id: string, albumKey: string): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await updateDoc(projectDocRef, { transitAlbumKey: albumKey });
    } catch (error) {
      console.error("Error setting transit album key:", error);
    }
  },

  getTransitAlbumKey: async (project_id: string): Promise<string | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      return project?.transitAlbumKey || null;
    } catch (error) {
      console.error("Error getting transit album key:", error);
      return null;
    }
  },

  setProjectPrivacy: async (project_id: string, isPrivate: boolean): Promise<void> => {
    try {
      const projectDocRef = doc(db, 'Projects', project_id);
      await updateDoc(projectDocRef, { isPrivate });
    } catch (error) {
      console.error(`Error setting privacy for project ${project_id}:`, error);
    }
  },

  getProjectPrivacy: async (project_id: string): Promise<boolean | null> => {
    try {
      const project = await ProjectManager.getProject(project_id);
      return project?.isPrivate ?? null;
    } catch (error) {
      console.error(`Error getting privacy for project ${project_id}:`, error);
      return null;
    }
  },

  createInviteLink: async (project_id: string): Promise<string | null> => {
    try {
      console.log(`Creating invite link for project ID: ${project_id}`);
      const inviteRef = await addDoc(collection(db, 'invites'), {
        project_id,
        createdAt: Timestamp.now(),
        expirationDate: Timestamp.fromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))
      });
      console.log(`Invite link created successfully with ID: ${inviteRef.id}`);
      // Use HTTPS URL
      const inviteLink = `https://invite-service-dot-overhear-2.uc.r.appspot.com/invite/${inviteRef.id}`;
      return inviteLink;
    } catch (error) {
      console.error('Error creating invite link:', error);
      return null;
    }
  },

  validateInviteLink: async (inviteId: string): Promise<string | null> => {
    try {
      console.log(`Validating invite link with ID: ${inviteId}`);
      const inviteDoc = await getDoc(doc(db, 'invites', inviteId));
    
      if (inviteDoc.exists()) {
        const inviteData = inviteDoc.data();
        const now = new Date();
        const expirationDate = inviteData.expirationDate.toDate();
        console.log(`Invite found. Expiration date: ${expirationDate}`);

        if (now < expirationDate) {
          console.log(`Invite is valid.`);
          return inviteData.project_id;
        } else {
          console.log(`Invite has expired.`);
        }
      } else {
        console.log(`Invite not found.`);
      }

      return null;
    } catch (error) {
      console.error('Error validating invite link:', error);
      return null;
    }
  },

  addUserToProject: async (userId: string, project_id: string): Promise<void> => {
    try {
      console.log(`Adding user ${userId} to project ${project_id}`);
      const db = getFirestore();
      const projectAccessRef = collection(db, 'ProjectAccess');
      await addDoc(projectAccessRef, {
        project_id,
        userId,
        accessLevel: 'read' // Default access level
      });
    } catch (error) {
      console.error('Error adding user to project:', error);
      throw error;
    }
  },

  getUserAccessibleProjects: async (userId: string): Promise<string[]> => {
    console.log(`ProjectManager.getUserAccessibleProjects: Starting for userId: ${userId}`);
    try {
      const projectAccessRef = collection(db, 'ProjectAccess');
      const q = query(projectAccessRef, where('userId', '==', userId));
      console.log(`ProjectManager.getUserAccessibleProjects: Querying ProjectAccess collection`);
      const querySnapshot = await getDocs(q);
      
      const projectIds = querySnapshot.docs.map(doc => doc.data().project_id);
      console.log(`ProjectManager.getUserAccessibleProjects: Found ${projectIds.length} accessible projects`);
      
      // Also include public projects
      const publicProjectsQuery = query(collection(db, 'Projects'), where('isPrivate', '==', false));
      console.log(`ProjectManager.getUserAccessibleProjects: Querying for public projects`);
      const publicProjectsSnapshot = await getDocs(publicProjectsQuery);
      const publicProjectIds = publicProjectsSnapshot.docs.map(doc => doc.id);
      console.log(`ProjectManager.getUserAccessibleProjects: Found ${publicProjectIds.length} public projects`);
      
      const allAccessibleProjects = [...new Set([...projectIds, ...publicProjectIds])];
      console.log(`ProjectManager.getUserAccessibleProjects: Total accessible projects: ${allAccessibleProjects.length}`);
      
      return allAccessibleProjects;
    } catch (error) {
      console.error("ProjectManager.getUserAccessibleProjects: Error fetching accessible projects:", error);
      return [];
    }
  },

  listenForProjectAccessChanges: (userId: string, callback: () => void): (() => void) => {
    console.log(`ProjectManager.listenForProjectAccessChanges: Setting up listener for userId: ${userId}`);
    const projectAccessRef = collection(db, 'ProjectAccess');
    const q = query(projectAccessRef, where('userId', '==', userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log(`ProjectManager.listenForProjectAccessChanges: Received snapshot with ${snapshot.docChanges().length} changes`);
      callback();
    }, (error) => {
      console.error("ProjectManager.listenForProjectAccessChanges: Error in listener:", error);
    });

    return unsubscribe;
  },
}