import React, { FunctionComponent, useState, useCallback } from "react";
import { IconButton } from "@mui/material";
import PortalPopup from "./PortalPopup";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { observer } from "mobx-react-lite";
import "./Accordion.css";
import { Icon } from ".";
import { useAdminMessageData } from "../hooks/useAdminMessageData";
import AdminMessageEditor from "./AdminMessageEditor";
import { constructUrl } from "../utilities";

export const MessageAccordion: FunctionComponent<{ messageKey: string }> = observer(({ messageKey }) => {
  const { adminMessage, updateAdminMessage, loading, error } = useAdminMessageData(messageKey);

  const [isEditAdminMessageOpen, setEditAdminMessageOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);  
  const getFilename = (path: string) => path.substring(path.lastIndexOf('/') + 1);

  const openEditAdminMessage = useCallback(() => {
    setEditAdminMessageOpen(true);
  }, []);

  const closeEditAdminMessage = useCallback(() => {
    setEditAdminMessageOpen(false);
  }, []);

  const toggleAccordion = useCallback(() => {
    setIsOpen(prevOpen => !prevOpen);
  }, []);

  const handleToggleAvailability = async () => {

    if (adminMessage) {
      try {
        const updatedAdminMessage = { ...adminMessage, isAvailable: !adminMessage.isAvailable };
        await updateAdminMessage(updatedAdminMessage);
      } catch (error) {
        console.error("Failed to toggle pin availability: ", error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error loading admin message:', error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="accordion">
      <div className="messages-accordion-header">
      <Icon icon="overheardark" style={{maxWidth: 60, marginLeft: 15}}/>
            {adminMessage?.isAvailable ? (
              <CheckBoxIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" onClick={handleToggleAvailability} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" onClick={handleToggleAvailability} />
            )}
        <div className="headingdata">{adminMessage?.author?.name}</div>
        <div className="headingdata">{adminMessage?.file?.title}</div>
        <div className="headingdata">{adminMessage?.file?.recordingPath && getFilename(adminMessage?.file.recordingPath)}</div>
            <div className="chevron-container" onClick={toggleAccordion}>
            <Icon 
              icon="chevron" 
              width={60} // Set the desired width
              height={20}
              className={`chevron-stroke-icon ${isOpen ? 'chevron-rotated' : ''}`} 
            />
        </div>
        <IconButton className="edit-container" color="primary" onClick={openEditAdminMessage}>
          <EditSharpIcon style={{ fontSize: 40, color: 'black' }} />
        </IconButton>
   
      </div>
      {isOpen && adminMessage && (
        <div className="recording-information">
        <div className="details">
          <div className="photo-container">
            <img
              className="author-image-icon"
              alt={adminMessage?.author?.name} 
              src={adminMessage?.author?.image ? constructUrl(adminMessage?.author.image) : undefined}
            />
          </div>
          <div className="content-container">
            <div className="recordinglabel">Bio:</div>
            <div className="recordingdata"> {adminMessage?.author?.bio}</div>
          </div>
          <div className="content-container">
            <div className="recordinglabel"> Description:</div>
            <div className="recordingdata"> {adminMessage?.file?.description}</div>
          </div>
        </div>
      </div>
    )}
      {isEditAdminMessageOpen && adminMessage && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeEditAdminMessage}
        >
          <AdminMessageEditor
            key={adminMessage.pinKey}
            onClose={closeEditAdminMessage}
            adminMessage={adminMessage}
            onSave={updateAdminMessage}
            pinKey={adminMessage.pinKey}
          />
        </PortalPopup>
      )}
    </div>
  );
});

export default MessageAccordion;