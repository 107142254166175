import { FunctionComponent, useState, useCallback, useEffect, useRef } from "react";
import PinEditor from "./PinEditor";
import PortalPopup from "./PortalPopup";
import "./ButtonRow.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "./Icon";
import { useAdminNav } from '../hooks';
import { handleSavePin } from "../utilities/handleSavePin";
import { ProjectManager } from "../models/ProjectManager";
import Switch from '@mui/material/Switch'; 
import { CopyToClipboard } from 'react-copy-to-clipboard'; 

type ButtonRowType = {
  settingsIcon?: React.ReactNode;
  showAddNewPinButton?: boolean;
  showLocationInput?: boolean;
  defaultView?: 'map' | 'list';
  userRole?: string;
  isTransitProjectEnabled?: boolean;
  
  centerMapOnLocation?: (location: string) => void;
  onMapViewButtonClick?: () => void;
  onListViewButtonClick?: () => void;
  handleMapViewClick: any,
  handleListViewClick: any,
};

export const ButtonRow: FunctionComponent<ButtonRowType> = ({
  settingsIcon,
  showAddNewPinButton,
  showLocationInput,
  centerMapOnLocation,
  defaultView,
  userRole,
  isTransitProjectEnabled, 
}) => {
  const navigate = useNavigate();
  const [isPinEditorOpen, setPinEditorOpen] = useState(false);
  const [activeView, setActiveView] = useState<'map' | 'list'>(defaultView || 'map');
  const [locationInput, setLocationInput] = useState<string>('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [inviteLink, setInviteLink] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  const inviteLinkGenerated = useRef(false); // Flag to track invite link generation

  const handleLocationInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationInput(e.target.value);
  }, []);

  const handleLocationSubmit = useCallback(() => {
    if (centerMapOnLocation) {
      centerMapOnLocation(locationInput);
    }
  }, [centerMapOnLocation, locationInput]);

  const handleMapViewClick = useCallback((userRole: string) => {
    setActiveView('map');
    navigate("/map-view", { state: { userRole } });
  }, [navigate, setActiveView]);
  
  const handleListViewClick = useCallback((userRole: string) => {
    setActiveView('list');
    navigate("/pin-list-view", { state: { userRole } });
  }, [navigate, setActiveView]);

  const openPinEditor = useCallback(() => {
    setPinEditorOpen(true);
  }, []);

  const closePinEditor = useCallback(() => {
    setPinEditorOpen(false);
  }, []);

  const { selectedProject } = useAdminNav();

  const generateInviteLink = useCallback(async () => {
    if (selectedProject && !inviteLinkGenerated.current) {
      try {
        console.log(`Generating invite link for project: ${selectedProject}`);
        const link = await ProjectManager.createInviteLink(selectedProject);
        if (!link) {
          throw new Error('Invite link is undefined');
        }
        // Ensure the link starts with https://
        const secureLink = link.startsWith('https://') ? link : `https://${link}`;
        setInviteLink(secureLink);
        inviteLinkGenerated.current = true;
        console.log(`Invite link generated: ${secureLink}`);
      } catch (error: unknown) {
        console.error("Error generating invite link:", error);
        setInviteLink(`Error generating link: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    } else {
      console.log('Invite link already generated or no project selected');
    }
  }, [selectedProject]);

  const openSettings = useCallback(() => {
    setIsSettingsOpen(true);
    if (selectedProject) {
      ProjectManager.getProject(selectedProject).then(project => {
        setIsPrivate(project?.isPrivate || false);
        if (project?.isPrivate && !inviteLinkGenerated.current) {
          console.log("Calling generateInviteLink from openSettings");
          generateInviteLink();
        }
      });
    }
  }, [selectedProject, generateInviteLink]);

  const closeSettings = useCallback(() => {
    setIsSettingsOpen(false);
  }, []);

  const handlePrivacyToggle = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsPrivate = event.target.checked;
    setIsPrivate(newIsPrivate);
    if (selectedProject) {
      await ProjectManager.updateProject(selectedProject, { isPrivate: newIsPrivate });
      if (newIsPrivate && !inviteLinkGenerated.current) {
        console.log("Calling generateInviteLink from handlePrivacyToggle");
        generateInviteLink();
      } else {
        setInviteLink('');
        inviteLinkGenerated.current = false; // Reset the flag
      }
    }
  }, [selectedProject, generateInviteLink]);



  return (
    <>
      <div className="button-row">
        <div className="settings-icon" onClick={openSettings}>
          <Icon icon="settings" size={50} color="black"/>
        </div>
        <div className="toggle">
          <button 
            className={`map-view-button ${activeView === 'map' ? 'active' : 'inactive'}`} 
            onClick={() => handleMapViewClick(userRole!)} 
          >
            <b className="mapview">Map View</b>
          </button>
          <button 
            className={`list-view-button ${activeView === 'list' ? 'active' : 'inactive'}`} 
            onClick={() => handleListViewClick(userRole!)}
          >
            <b className="list-view">List View</b>
          </button>
        </div>
        {showAddNewPinButton && !isTransitProjectEnabled ? (
          <button className="button-action-submit" onClick={openPinEditor}>
            <b className="add-new-pin">Add New Pin</b>
          </button>
        ) : (
          <div className="button-placeholder"></div>
        )}
        {showLocationInput && (
          <div className="location">
            <b className="enter-a-post">
              Enter a Post Code or location to center the map
            </b>
            <input
              className="location-input"
              placeholder="Location"
              type="text"
              value={locationInput}
              onChange={handleLocationInputChange}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleLocationSubmit();
                }
              }}
            />
          </div>
        )}
      </div>
      {isPinEditorOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closePinEditor}
        >
          <PinEditor 
            onClose={closePinEditor} 
            onSave={(pin) => handleSavePin(pin, selectedProject, closePinEditor)}
            pinData={null}
          />
        </PortalPopup>
      )}
      {isSettingsOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSettings}
        >
          <div className="settings-modal">
            <h2>Project Settings</h2>
            <div className="setting-item">
              <span>Private Project</span>
              <Switch
                checked={isPrivate}
                onChange={handlePrivacyToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isPrivate && (
              <div className="invite-link-section">
                <h3>Invite Link</h3>
                <div className="invite-link-container">
                  <input 
                    type="text" 
                    value={inviteLink} 
                    readOnly 
                    className="invite-link-input"
                  />
                  <CopyToClipboard 
                    text={inviteLink}
                    onCopy={() => {
                      setIsCopied(true);
                      setTimeout(() => setIsCopied(false), 2000);
                    }}
                  >
                    <button className="copy-button">
                      {isCopied ? 'Copied!' : 'Copy'}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
            <button className="close-button" onClick={closeSettings}>Close</button>
          </div>
        </PortalPopup>
      )}
    </>
  );
};

export default ButtonRow;
