import { FunctionComponent, useState, useEffect, useCallback } from "react";
import AllDataStore from "../stores/AllDataStore";
import { AdminNav, ButtonRow, PinAccordion, Icon, RecordingAccordion, RecordingEditor, PinEditor } from "../components";
import { useNavigate } from "react-router-dom";
import "./ListPages.css";
import { IPin, IPinWithRecordings, IRecordingWithAuthor } from "../types";
import AllDataManager from "../models/AllDataManager";
import { PinManager, ProjectManager, RecordingManager } from "../models";
import RecordingStore from "../stores/RecordingStore";
import { observer } from "mobx-react-lite";
import pinStore, { PinStore } from "../stores/PinStore";
import { useAdminNav } from "../hooks/useAdminNav";
import { DragDropContext, Droppable, Draggable, DropResult, DragStart, DragUpdate } from 'react-beautiful-dnd';
import Sidebar from "../components/Sidebar";
import { Button } from "@mui/material";
import PortalPopup from "../components/PortalPopup";
import { handleSavePin } from "../utilities/handleSavePin"; // Import the modularized function

const PinListView: FunctionComponent = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isRecordingEditorOpen, setIsRecordingEditorOpen] = useState(false);
  const [isPinEditorOpen, setIsPinEditorOpen] = useState(false)
  const sortedPins = Array.from(pinStore.pins.values()).sort((a, b) => Number(a.sequentialOrderNum) - Number(b.sequentialOrderNum));
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();
  const selectedProjectDetails = projects.find(project => project.project_id === selectedProject);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOrphanDragging, setIsOrphanDragging] = useState(false);
  const [dragOverPinKey, setDragOverPinKey] = useState<string | null>(null);
  const [firstPinAlbumKey, setFirstPinAlbumKey] = useState<string | null>(null);
  const [transitRecordings, setTransitRecordings] = useState<IRecordingWithAuthor[]>([]);


  useEffect(() => {
    const fetchTransitAlbumKey = async () => {
      if (selectedProjectDetails?.isTransitProjectEnabled) {
        const albumKey = await ProjectManager.getTransitAlbumKey(selectedProject);
        setFirstPinAlbumKey(albumKey);
      }
    };

    fetchTransitAlbumKey();
  }, [selectedProject, selectedProjectDetails?.isTransitProjectEnabled]);

  const handleSavePinWrapper = async (pin: IPin) => {
    if (selectedProjectDetails?.isTransitProjectEnabled && !firstPinAlbumKey && typeof pin.albumKey === 'string') {
      setFirstPinAlbumKey(pin.albumKey);
      await ProjectManager.setTransitAlbumKey(selectedProject, pin.albumKey);
      console.log("Setting firstPinAlbumKey:", pin.albumKey);
    }
    await handleSavePin(pin, selectedProject, closePinEditor);
  };

  const closeSidebar = () => {
    console.log("Closing sidebar");
    setIsDrawerOpen(false);
  };

  const onDragStart = (start: DragStart) => {
    // Check if the drag starts from the sidebar
    setIsOrphanDragging(start.source.droppableId === "sidebar");
  };

  const onDragUpdate = (update: DragUpdate) => {
    const { destination } = update;
    if (destination && destination.droppableId === "droppablePins") {
      setDragOverPinKey(sortedPins[destination.index].pinKey);
    } else {
      setDragOverPinKey(null);
    }
  };

  const onDragEnd = useCallback(async (result: DropResult) => {
    const { source, destination, draggableId, type } = result;

    if (!destination) return;

    if (type === "RECORDING" && source.droppableId === "transitRecordings" && destination.droppableId === "transitRecordings") {
      const newTransitRecordings = Array.from(transitRecordings);
      const [reorderedItem] = newTransitRecordings.splice(source.index, 1);
      newTransitRecordings.splice(destination.index, 0, reorderedItem);

      // Immediately update local state
      setTransitRecordings(newTransitRecordings);

      try {
        // Update the collection order in the database
        await RecordingManager.updateRecordingsOrder(
          selectedProject,
          newTransitRecordings.map((item, index) => ({ key: item.key, order: index }))
        );
        
        // Update the RecordingStore
        RecordingStore.setTransitRecordings(selectedProject, newTransitRecordings);
      } catch (error) {
        console.error("Error updating recordings order:", error);
        // Revert to original order if update fails
        setTransitRecordings(transitRecordings);
      }
    } else {
      // Handle existing pin dragging logic
      if (source.droppableId === "sidebar" && destination.droppableId === "droppablePins") {
        const pin = sortedPins[destination.index];
        if (!pin) return;

        const recordingId = draggableId;
        const pinId = pin.pinKey;

        try {
          // Assign the recording to the pin
          await RecordingManager.assignRecordingToPin(recordingId, pinId);

          // Fetch the updated recording with its new albumKey
          const recording = await RecordingManager.getRecording(recordingId);

          // Update RecordingStore with the new recording data
          if (recording) {
            RecordingStore.addRecording(recording);
          }
          // Update the pin in PinStore if the albumKey was updated
          if (recording && recording.albumKey) {
            const updatedPin = { ...pin, albumKey: recording.albumKey };
            pinStore.setPin(pinId, updatedPin);
          }
        } catch (error) {
          console.error("Error assigning recording to pin:", error);
        }
      } else if (source.droppableId === "droppablePins" && destination.droppableId === "droppablePins") {
        // Handle reordering of pins
        const items = Array.from(pinStore.pins.values());
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        const updatedPins = items.map((pin, index) => ({
          ...pin,
          sequentialOrderNum: index + 1
        }));

        pinStore.setPins(updatedPins);
        try {
          await PinManager.updateFirestoreWithNewOrder(updatedPins);
        } catch (error) {
          console.error("Error updating order in Firestore:", error);
          pinStore.setPins(items); // Revert to original order if update fails
        }
      }
    }

    // Reset any drag-related states
    setDragOverPinKey(null);
    setIsOrphanDragging(false);
    closeSidebar();
  }, [transitRecordings, selectedProject, sortedPins, pinStore.pins]);

  useEffect(() => {
    const fetchPins = async () => {
      setIsLoading(true);
      try {
        const fetchedPins: IPinWithRecordings[] = await AllDataManager.observeProjectData(selectedProject);

        const projectPins = fetchedPins.filter(pin => pin.project === selectedProject);

        AllDataStore.setPins(projectPins);
        // Extract recordings from the fetched pins and store them in the RecordingStore
        const allRecordings = projectPins.flatMap(pin => pin.recordings);
        RecordingStore.setRecordings(allRecordings);

        pinStore.setPins(projectPins.map(pin => ({
          ...pin,
          recordings: [], // Exclude recordings and authors if you don't want them in PinStore
        })));

        setIsLoading(false); // Set loading to false after pins are set
      } catch (error) {
        setError(error as Error); // Set error state if there's an error
        setIsLoading(false); // Also set loading to false in case of error
      }
    }

    fetchPins();
  }, [selectedProject]);


  useEffect(() => {
    const fetchTransitRecordings = async () => {
      if (selectedProjectDetails?.isTransitProjectEnabled) {
        setIsLoading(true);
        try {
          const recordings = await RecordingManager.fetchRecordingsForTransitProject(selectedProject);
          RecordingStore.setTransitRecordings(selectedProject, recordings);
          setTransitRecordings(RecordingStore.getTransitRecordings(selectedProject));
        } catch (error) {
          console.error("Error fetching transit recordings:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTransitRecordings();
  }, [selectedProject, selectedProjectDetails]);

  useEffect(() => {
    // This will now react whenever the pins Map changes, which should be after setPins is called
  }, [pinStore.pins, selectedProject]);


  const openRecordingEditor = () => {
    setIsRecordingEditorOpen(true);
  };

  const closeRecordingEditor = () => {
    setIsRecordingEditorOpen(false);
  };

  const openPinEditor = () => {
    setIsPinEditorOpen(true);
  };

  const closePinEditor = () => {
    setIsPinEditorOpen(false);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <div className="list-page">
        <AdminNav
          imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
          projectAdminHeight="unset"
          projectAdminPosition="unset"
          projectAdminTop="unset"
          projectAdminLeft="unset"
          projectName={projectName}
          userRole={userRole}
          projects={projects}
          selectedProject={selectedProject}
          onProjectChange={onProjectChange}
        />

        <ButtonRow
          settingsIcon={<Icon icon="settings" size={24} />}
          showLocationInput={false}
          showAddNewPinButton={true}
          handleMapViewClick={() => navigate("/map-view")}
          handleListViewClick={() => navigate("/pin-list-view")}
          defaultView="list"
          userRole={userRole}
          isTransitProjectEnabled={selectedProjectDetails?.isTransitProjectEnabled}
        />

        {!selectedProjectDetails?.isTransitProjectEnabled && (
          <Sidebar
            selectedProject={selectedProject}
            closeSidebar={closeSidebar}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        )}

        {selectedProjectDetails?.isTransitProjectEnabled ? (
          <div className="split-view">
            <div className="pin-column">
              <div className="transit-pin-header">
                <a className="header-item"></a>
                <a className="header-item"></a>
                <a className="header-item">Live</a>
                <a className="header-item">Pin Name</a>
                <a className="header-item">Downloads</a>
                <a className="header-item">Type</a>
                <Button className="transit-button-action-submit" onClick={openPinEditor}>
                  <b className="add-new-button">Add new Pin</b>
                </Button>
              </div>
              <Droppable droppableId="droppablePins" direction="vertical">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={`dragstyles ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
                    {sortedPins.length > 0
                      ? sortedPins.map((pin, index) => (
                        <Draggable
                          key={pin.pinKey}
                          draggableId={pin.pinKey}
                          index={index}
                          isDragDisabled={isOrphanDragging || !selectedProjectDetails?.isSequentialEnabled}
                        >
                          {(provided) => {
                            const style = provided.draggableProps.style;
                            let newY = '0px';
                            if (style?.transform) {
                              const match = /translate\(([^,]*), ([^)]*)\)/.exec(style.transform);
                              newY = match ? match[2] : '0px';
                            }

                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...style,
                                  transform: `translate(0px, ${newY})`
                                }}
                                className={`dragstyles ${
                                  index === 0 ? "first-accordion" :
                                    index === sortedPins.length - 1 ? "last-accordion" : ""
                                }`}
                              >
                                <PinAccordion
                                  key={`${pin.pinKey}-${pin.sequentialOrderNum}`}
                                  pinKey={pin.pinKey}
                                  dragHandleProps={provided.dragHandleProps}
                                  isSequentialEnabled={selectedProjectDetails?.isSequentialEnabled}
                                  isDragOver={pin.pinKey === dragOverPinKey && isOrphanDragging}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      ))
                      : <div className="firstPin">Add your first Pin.</div>
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className="recording-column">
              <div className="transit-recording-header">
                <a className="header-item">Recording Name</a>
                <a className="header-item">Artist</a>
                <a className="header-item">File Name</a>
                {selectedProjectDetails?.isTransitProjectEnabled && (
                  <Button className="transit-button-action-submit" onClick={openRecordingEditor}>
                    <b className="add-new-button">Add new Recording</b>
                  </Button>
                )}
              </div>
              <Droppable droppableId="transitRecordings" type="RECORDING">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {isLoading ? (
                      <div>Loading recordings...</div>
                    ) : transitRecordings.length > 0 ? (
                      transitRecordings.map((recording, index) => (
                        <Draggable key={recording.key} draggableId={recording.key} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <RecordingAccordion
                                key={recording.key}
                                pinKey={"transit"}
                                recordingKey={recording.key}
                                sourceView="PinList"
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <div>No recordings found.</div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        ) : (
          <div className="pin-column">
            <div className="header">
              <a className="header-item"></a>
              <a className="header-item"></a>
              <a className="header-item">Live</a>
              <a className="header-item">Pin Name</a>
              {selectedProjectDetails?.isSequentialEnabled && <a className="header-item">Order</a>}
              <a className="header-item">Downloads</a>
              <a className="header-item">Type</a>
            </div>
            <Droppable droppableId="droppablePins" direction="vertical">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={`dragstyles ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}>
                  {sortedPins.length > 0
                    ? sortedPins.map((pin, index) => (
                      <Draggable
                        key={pin.pinKey}
                        draggableId={pin.pinKey}
                        index={index}
                        isDragDisabled={isOrphanDragging || !selectedProjectDetails?.isSequentialEnabled}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              transform: `translate(0px, ${provided.draggableProps.style?.transform?.match(/translate\(([^,]*), ([^)]*)\)/)?.[2] || '0px'})`
                            }}
                            className={`dragstyles ${
                              index === 0 ? "first-accordion" :
                                index === sortedPins.length - 1 ? "last-accordion" : ""
                            }`}
                          >
                            <PinAccordion
                              key={`${pin.pinKey}-${pin.sequentialOrderNum}`}
                              pinKey={pin.pinKey}
                              dragHandleProps={provided.dragHandleProps}
                              isSequentialEnabled={selectedProjectDetails?.isSequentialEnabled}
                              isDragOver={pin.pinKey === dragOverPinKey && isOrphanDragging}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                    : <div className="firstPin">Add your first Pin.</div>
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}

        {isPinEditorOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top right"
            onOutsideClick={closePinEditor}
          >
            <PinEditor
              onClose={closePinEditor}
              onSave={handleSavePinWrapper}
              pinData={null}
              firstPinAlbumKey={firstPinAlbumKey}
            />
          </PortalPopup>
        )}

        {isRecordingEditorOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top right"
            onOutsideClick={closeRecordingEditor}
          >
            <RecordingEditor
              onClose={closeRecordingEditor}
              recordingKey={undefined}
              recording={undefined}
              pinKey={undefined}
              pinType={undefined}
              albumKey={firstPinAlbumKey}
              sourceView="PinList"
              onRecordingAdded={() => {
                RecordingStore.fetchRecordingsForPin(selectedProject);
              }}
              config={{
                locationInfo: false,
                authorInfo: true,
                recordingInfo: true,
                uploadFields: true,
              }}
            />
          </PortalPopup>
        )}
      </div>
    </DragDropContext>
  );
});

  export default PinListView;




