import { FunctionComponent, useState } from "react";
import FormLogin from "../components/FormLogin";
import "./LoginScreen.css";

const LoginScreen: FunctionComponent = () => {
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);

  const toggleForm = () => {
    setIsProjectAdmin(!isProjectAdmin);
  };

  return (
    <div className="loginscreen">
      <img
        className="logo-container-icon"
        alt=""
        src="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
      />
      
       <FormLogin onToggleForm={toggleForm} isProjectAdmin={isProjectAdmin} />
    </div>
  );
};

export default LoginScreen;