import React, { useState, useCallback, useEffect } from "react";
import { handleFileUpload } from "../utilities";
import { deleteFile } from "../firebase/storage";
import { AdminMessageManager, AuthorManager } from "../models";
import { IAdminMessage } from "../types/AdminMessage";
import adminMessageStore from "../stores/AdminMessageStore";
import { AdminMessageForm, AdminMessageFormProps } from './AdminMessageForm';
import { auth } from '../firebase/firebase';
import UserManager from "../models/UserManager";
import { v4 as uuidv4 } from 'uuid';

type AdminMessageEditorProps = {
  onClose: () => void;
  adminMessage?: IAdminMessage;
  onSave: (updatedAdminMessage: IAdminMessage) => Promise<void>;
  pinKey?: string;
};

const AdminMessageEditor: React.FC<AdminMessageEditorProps> = ({ onClose, adminMessage, onSave, pinKey }) => {
  const [authorBio, setAuthorBio] = useState(adminMessage?.author?.bio || '');
  const [recordingDescription, setRecordingDescription] = useState(adminMessage?.file?.description || '');
  const [recordingTitle, setRecordingTitle] = useState(adminMessage?.file?.title || '');
  const [recordingURL, setRecordingURL] = useState(adminMessage?.file?.audioURL || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(
    adminMessage?.file?.recordingPath ? adminMessage.file.recordingPath.split('/').pop() || null : null
  );
  const [isUploading, setIsUploading] = useState(false);

  const isEditMode = !!adminMessage;

  const handleDelete = async () => {
    if (!pinKey || !isEditMode) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this adminMessage?');
    if (confirmDelete) {
      setIsUploading(true);
      try {
        if (adminMessage?.file?.recordingPath) {
          await deleteFile(adminMessage.file.recordingPath);
        }
        await AdminMessageManager.removeAdminMessage(pinKey);
        adminMessageStore.removeAdminMessage(pinKey);
        onClose();
      } catch (error) {
        console.error('Failed to delete recording:', error);
        alert('There was an error deleting the recording.');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleSave = async () => {
    setIsUploading(true);
    try {
      let authorKey = adminMessage?.author?.authorKey;
      let oldRecordingPath = adminMessage?.file?.recordingPath;
      let newRecordingPath = oldRecordingPath;

      if (!isEditMode) {
        const userId = auth.currentUser?.uid;
        if (!userId) throw new Error('No user is currently logged in.');

        const currentUser = await UserManager.getCurrentUser(userId);
        if (!currentUser || !currentUser.userKey) {
          throw new Error('Failed to fetch the current user data or userKey is missing.');
        }

        authorKey = '-Ma3YKCEmmxAV_xoSLpu'; // Admin author key
      } else if (!authorKey) {
        const existingAuthor = await AuthorManager.getAuthorByName(adminMessage?.author?.name || '');
        if (existingAuthor) authorKey = existingAuthor.authorKey;
      }

      const prependTimestamp = (fileName: string) => `${Date.now()}-${fileName}`;

      if (selectedFile && selectedFile.type === 'audio/mpeg') {
        if (oldRecordingPath) await deleteFile(oldRecordingPath);
        const timestampedFileName = prependTimestamp(selectedFile.name);
        newRecordingPath = await handleFileUpload(
          selectedFile,
          setRecordingURL,
          `Admin Messages/`,
          timestampedFileName
        );
      }

      if (isEditMode && adminMessage?.pinKey && newRecordingPath !== oldRecordingPath) {
        await AdminMessageManager.updateAdminMessage(adminMessage.pinKey, {
          file: { ...adminMessage.file, recordingPath: newRecordingPath },
        });
      }

      const updatedAdminMessage: IAdminMessage = {
        ...adminMessage,
        pinKey: adminMessage?.pinKey || uuidv4(),
        author: {
          ...adminMessage?.author,
          authorKey,
          bio: authorBio,
          website: '',
          tags: { authorTags: [] },
        },
        file: {
          description: recordingDescription,
          audioURL: recordingURL,
          recordingPath: newRecordingPath || '',
          title: recordingTitle,
          size: selectedFile?.size || adminMessage?.file?.size || 0,
        },
        ownership: {
          recordingNarrator: '',
          recordingAuthor: authorKey!,
          recordingOwner: isEditMode ? adminMessage?.ownership?.recordingOwner || null : auth.currentUser?.uid || null,
        },
      };

      if (isEditMode) {
        await onSave(updatedAdminMessage);
      } else {
        const savedPinKey = await AdminMessageManager.createAdminMessage(updatedAdminMessage);
        if (savedPinKey) {
          await adminMessageStore.fetchAndAddMessage(savedPinKey);
          onSave(updatedAdminMessage);
        } else {
          throw new Error('Failed to create new Admin Message: pinKey is undefined or not a string');
        }
      }

      onClose();
    } catch (error) {
        console.error(`Error ${isEditMode ? 'saving' : 'creating'} Admin Message:`, error);
        // Handle errors, e.g., show an error message to the user
      } finally {
        setIsUploading(false);
      }
    };
    
    const formProps: AdminMessageFormProps = {
      adminMessage,
      authorBio,
      recordingDescription,
      recordingTitle,
      recordingURL,
      selectedFile,
      selectedFileName,
      isUploading,
      onAuthorBioChange: (e) => setAuthorBio(e.target.value),
      onRecordingDescriptionChange: (e) => setRecordingDescription(e.target.value),
      onRecordingTitleChange: (e) => setRecordingTitle(e.target.value),
      onRecordingDetailsChange: (details) => {
        setRecordingURL(details.recordingURL);
        setSelectedFile(details.file || null);
        setSelectedFileName(details.file?.name || null);
      },
      onCancel: onClose,
      onSave: handleSave,
      onDelete: isEditMode ? handleDelete : undefined,
    };
    
    return <AdminMessageForm {...formProps} />;
    };
    
    export default React.memo(AdminMessageEditor);
