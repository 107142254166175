import { IPin } from "../types";
import PinStore from "../stores/PinStore";

export const handleSavePin = async (pin: IPin, selectedProject: string, closePinEditor: () => void) => {
  try {
    // Ensure the pin has the correct project
    const pinWithProject = {
      ...pin,
      project: selectedProject,
    };

    // Save the new pin to Firestore
    await PinStore.createPin(pinWithProject);

    console.log('New pin saved:', pinWithProject);
    closePinEditor();
  } catch (error) {
    console.error('Error creating pin:', error);
    // You might want to show an error message to the user here
  }
};