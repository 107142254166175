import { deleteFile } from "../firebase/storage";
import { IRecordingWithAuthor } from "../types";
import { RecordingManager, TagManager, ProjectManager } from "../models";
import recordingStore from "../stores/RecordingStore";

export async function handleDelete(
  recordingKey: string,
  pinKey?: string,
  onClose?: () => void,
  setIsUploading?: (isUploading: boolean) => void,
  recording?: IRecordingWithAuthor,
  fileSize?: number
) {
  if (!recordingKey) return;

  const confirmDelete = window.confirm('Are you sure you want to delete this recording?');
  if (confirmDelete) {
    setIsUploading?.(true); // Use optional chaining
    try {
      if (recording?.qrPath) {
        await deleteFile(recording.qrPath);
      }
      if (fileSize && recording?.project) {
        await ProjectManager.updateProjectAudioSize(recording.project, -fileSize);
      }
      if (recording?.author?.tags?.authorTags && pinKey) {
        for (const tag of recording.author.tags.authorTags) {
          await TagManager.removePinKeyFromAuthorTag(tag, pinKey);
        }
      }
      if (recording?.tags?.subjectTags && pinKey) {
        for (const tag of recording.tags.subjectTags) {
          await TagManager.removeRecordingKeyFromTag(tag, recordingKey, pinKey);
        }
      }
      if (recording?.tags?.genreTags && pinKey) {
        for (const tag of recording.tags.genreTags) {
          await TagManager.removeRecordingKeyFromGenreTag(tag, recordingKey, pinKey);
        }
      }
      if (recording?.file?.recordingPath) {
        await deleteFile(recording.file.recordingPath);
      }
      await RecordingManager.removeRecording(recordingKey);
      recordingStore.removeRecording(recordingKey);
      onClose?.(); // Use optional chaining
    } catch (error) {
      console.error('Failed to delete recording:', error);
      alert('There was an error deleting the recording.');
    } finally {
      setIsUploading?.(false); // Use optional chaining
    }
  }
}
