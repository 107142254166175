import React, { useEffect, useState, useCallback } from 'react';
import { TextField, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import TagInputField from './TagInputField';
import './EditRecording.css';

interface RecordingInputFieldsProps {
    onRecordingInfoChange: (info: {
        title: string;
        description: string;
        narrator: string;
        subjectTags: string[];
        genreTags: string[];
        whereQRCode?: string;
    }) => void;
    pinType?: string;
    initialTitle?: string;
    initialDescription?: string;
    initialNarrator?: string;
    initialWhereQRCode?: string;
    subjectTags: string[];
    genreTags: string[];
    availableGenreTags: string[];
    setSubjectTags: React.Dispatch<React.SetStateAction<string[]>>;
    setGenreTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const RecordingInputFields: React.FC<RecordingInputFieldsProps> = ({
    onRecordingInfoChange,
    pinType,
    initialTitle = '',
    initialDescription = '',
    initialNarrator = '',
    initialWhereQRCode = '',
    subjectTags,
    genreTags,
    availableGenreTags,
    setSubjectTags,
    setGenreTags
}) => {
    const [recordingTitle, setRecordingTitle] = useState(initialTitle);
    const [recordingDescription, setRecordingDescription] = useState(initialDescription);
    const [recordingNarrator, setRecordingNarrator] = useState(initialNarrator);
    const [whereQRCode, setWhereQRCode] = useState(initialWhereQRCode);

    const updateRecordingInfo = useCallback(() => {
        onRecordingInfoChange({
            title: recordingTitle,
            description: recordingDescription,
            narrator: recordingNarrator,
            subjectTags,
            genreTags,
            whereQRCode
        });
    }, [recordingTitle, recordingDescription, recordingNarrator, subjectTags.join(','), genreTags.join(','), whereQRCode, onRecordingInfoChange]);

    useEffect(() => {
        updateRecordingInfo();
    }, [updateRecordingInfo]);

    useEffect(() => {
        console.log('RecordingInformationFields - subjectTags:', subjectTags);
        console.log('RecordingInformationFields - genreTags:', genreTags);
    }, [subjectTags, genreTags]);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordingTitle(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordingDescription(event.target.value);
    };

    const handleNarratorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordingNarrator(event.target.value);
    };

    const handleWhereQRCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWhereQRCode(event.target.value);
    };

    const handleSubjectTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
        setSubjectTags(prevTags => {
            const updatedTags = typeof newTags === 'function' ? newTags(prevTags) : newTags;
            return updatedTags;
        });
    };

    const handleGenreTagChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        setGenreTags([newValue]);
    };

    return (
        <div className="recording-info-container">
            <TextField
                label="Title"
                variant="filled"
                fullWidth
                value={recordingTitle}
                onChange={handleTitleChange}
            />
            <TextField
                label="Description"
                variant="filled"
                fullWidth
                multiline
                rows={4}
                value={recordingDescription}
                onChange={handleDescriptionChange}
            />
            <TextField
                label="Narrator"
                variant="filled"
                fullWidth
                value={recordingNarrator}
                onChange={handleNarratorChange}
            />
            {pinType === 'qrCode' && (
                <TextField
                    label="Where is the QR?"
                    variant="filled"
                    fullWidth
                    value={whereQRCode}
                    onChange={handleWhereQRCodeChange}
                />
            )}
            <div className="author-tags-input-container">
                <a className="author-tags">Subject Tags</a>
                <TagInputField
                    tags={subjectTags}
                    setTags={handleSubjectTagsChange}
                    className='author-tags-input-container'
                />
            </div>
            <div style={{marginBottom:50}}></div>
            <div className="author-tags-input-container">
                <div className="subject-tags1">Genre Tags</div>
                <Select
                    className="input-container"
                    color="primary"
                    label="Enter a new Tag"
                    size="medium"
                    variant="standard"
                    value={genreTags[0] || ''}
                    onChange={handleGenreTagChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {availableGenreTags.length > 0 ? (
                        availableGenreTags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                                {tag}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value={genreTags[0] || ''} disabled>
                            {genreTags[0] || 'No available tags'}
                        </MenuItem>
                    )}
                </Select>
            </div>
        </div>
    );
};

export default RecordingInputFields;