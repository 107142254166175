// src/pages/ProjectView.tsx
import React, { useCallback, useEffect, useState } from 'react';
import AdminNav from '../components/AdminNav';
import ProjectItem from '../components/ProjectItem';
import { IProject } from '../types/Project';
import { useAdminNav } from '../hooks/useAdminNav';
import { ProjectManager } from '../models/ProjectManager';
import "./ListPages.css"
import { observer } from 'mobx-react-lite'; // Import observer from mobx-react-lite
import { projectStore } from '../stores/ProjectStore'; // Import projectStore
import AdminButtonRow from '../components/AdminButtonRow';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../components';

const ProjectView: React.FC = observer(() => { 
  const navigate = useNavigate();
  const { userRole } = useAdminNav();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [isCreateProjectOpen, setCreateProjectOpen] = useState(false);
  const openCreateProject = useCallback(() => {
    setCreateProjectOpen(true);
  }, []);



  useEffect(() => {
    projectStore.fetchProjects(); // Fetch projects using ProjectStore
  }, []); 

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectData = await ProjectManager.observeProjects(); 
        setProjects(projectData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []); // Empty dependency array to fetch only on component mount

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!projects || projects.length === 0) {
    return <div>No project data available.</div>;
  }

  return (
    <div className='list-page'>
      <AdminNav
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        showProjectDropdown={false} 
        userRole={userRole}
      />
      <AdminButtonRow
        settingsIcon={<Icon icon="settings" size={24} />}
        handleAdminMessageViewClick={() => navigate("/message-view")}
        handleProjectViewClick={() => navigate("/project-view")}
        handleWandersViewClick={() => navigate("/wanders-view")}
        defaultView="project-view"
        userRole={userRole}

      />
      <div className="project-list-header">
        <a className="header-item">Live</a>
        <a className="header-item">Icon</a>
        <a className="header-item">Name</a>
        <a className="header-item">Pins</a>
        {/* <a className="header-item">Downloads</a> */}
        <a className="header-item">Tags</a>
      </div>
      <div className='body'>
        {projectStore.projects.map((project) => ( // Use projects from ProjectStore
          <ProjectItem key={project.project_id} project={project} />
        ))}
      </div>
    </div>
  );
});

export default ProjectView;
