import React, { useState, useEffect } from 'react';
import { TextField, Button } from "@mui/material";
import TagInputField from './TagInputField';
import { IAuthorTags } from "../types";
import "./EditRecording.css";

interface AuthorInformationFieldsProps {
    initialAuthorName?: string;
    initialAuthorBio?: string;
    initialAuthorWebsite?: string;
    initialAuthorTags?: string[];
    initialAuthorImageUrl?: string;
    onAuthorDetailsChange: (details: {
        authorName: string;
        authorBio: string;
        authorWebsite: string;
        authorTags: string[];
        imageFile?: File | null;
    }) => void;
    setAuthorTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const AuthorInformationFields: React.FC<AuthorInformationFieldsProps> = ({
    initialAuthorName = '',
    initialAuthorBio = '',
    initialAuthorWebsite = '',
    initialAuthorTags = [],
    initialAuthorImageUrl = '/assets/icons/user.svg',
    onAuthorDetailsChange,
    setAuthorTags
}) => {
    const [authorName, setAuthorName] = useState(initialAuthorName);
    const [authorBio, setAuthorBio] = useState(initialAuthorBio);
    const [authorWebsite, setAuthorWebsite] = useState(initialAuthorWebsite);
    const [authorTags, setLocalAuthorTags] = useState<string[]>(initialAuthorTags);
    const [authorImageUrl, setAuthorImageUrl] = useState(initialAuthorImageUrl);
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

    useEffect(() => {
        // Update local state when initial values change
        setAuthorName(initialAuthorName);
        setAuthorBio(initialAuthorBio);
        setAuthorWebsite(initialAuthorWebsite);
        setLocalAuthorTags(initialAuthorTags);
        setAuthorImageUrl(initialAuthorImageUrl);
    }, [initialAuthorName, initialAuthorBio, initialAuthorWebsite, initialAuthorTags, initialAuthorImageUrl]);

    const handleAuthorTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
        const updatedTags = typeof newTags === 'function' ? newTags(authorTags) : newTags;
        setLocalAuthorTags(updatedTags);
        setAuthorTags(updatedTags);
        updateDetails({ authorTags: updatedTags });
    };

    const updateDetails = (details?: Partial<{
        authorName: string;
        authorBio: string;
        authorWebsite: string;
        authorTags: string[];
        imageFile?: File | null;
    }>) => {
        const updatedDetails = {
            authorName,
            authorBio,
            authorWebsite,
            authorTags,
            ...details
        };
        onAuthorDetailsChange(updatedDetails);
    };

    useEffect(() => {
        // Revoke the object URL to avoid memory leaks
        return () => {
            if (authorImageUrl.startsWith('blob:')) {
                URL.revokeObjectURL(authorImageUrl);
            }
        };
    }, [authorImageUrl]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const newImageUrl = URL.createObjectURL(file);
            setAuthorImageUrl(newImageUrl);
            setSelectedImageFile(file);
            updateDetails({
                authorName,
                authorBio,
                authorWebsite,
                authorTags,
                imageFile: file
            });
        }
    };

    useEffect(() => {
        updateDetails();
    }, [authorName, authorBio, authorWebsite, authorTags.join(','), selectedImageFile]);

    return (
        <div className="author-information-container">
            <a className="author-information2">Author Information</a>
            <div className="image-uploader">
                <img className="artist-image" alt={authorName} src={authorImageUrl} />
                <Button
                    color="primary"
                    size="medium"
                    variant="contained"
                    component="label"
                >
                    Select File
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </Button>
            </div>
            <TextField
                className="input-container"
                label="Name"
                variant="filled"
                value={authorName}
                onChange={(e) => { setAuthorName(e.target.value); updateDetails(); }}
            />
            <TextField
                className="input-container"
                label="Bio"
                variant="filled"
                value={authorBio}
                onChange={(e) => { setAuthorBio(e.target.value); updateDetails(); }}
                multiline
                rows={4}
            />
            <TextField
                className="input-container"
                label="Website"
                variant="filled"
                value={authorWebsite}
                onChange={(e) => { setAuthorWebsite(e.target.value); updateDetails(); }}
            />
            <a className="author-tags">Author Tags</a>
            <TagInputField
                tags={authorTags}
                setTags={handleAuthorTagsChange}
                className="author-tags-input-container"
                chipContainerClassName="tag-chip-container"
            />
        </div>
    );
};

export default AuthorInformationFields;