import React, { FunctionComponent, useState, useCallback } from "react";
import { Button, IconButton, CircularProgress } from "@mui/material";
import PortalPopup from "./PortalPopup";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import RecordingEditor from "./RecordingEditor"; 
import EditWander from "./EditWander";
import { observer } from "mobx-react-lite";
import "./Accordion.css";
import { Icon, RecordingAccordion } from "../components";
import { useWanderData } from "../hooks";
import { IWanderPin } from "../types";
import RecordingStore from "../stores/RecordingStore";

interface WanderAccordionProps {
    wanderKey: string;
    dragHandleProps?: any;
}

export const WanderAccordion: FunctionComponent<WanderAccordionProps> = observer(({ wanderKey, dragHandleProps }) => {
    const { wanderData, updateWander, recordings, loading, error } = useWanderData(wanderKey);
    const [isEditWanderOpen, setEditWanderOpen] = useState(false);
    const [isRecordingEditorOpen, setRecordingEditorOpen] = useState(false); 
    const [isOpen, setIsOpen] = useState(false);
    const EditWanderMemoized = React.memo(EditWander);
    const [config] = useState({
        locationInfo: true,
        authorInfo: true,
        recordingInfo: true,
        uploadFields: true,
    });

    const openRecordingEditor = useCallback(() => { 
        setRecordingEditorOpen(true);
    }, []);

    const closeRecordingEditor = useCallback(() => { 
        setRecordingEditorOpen(false);
    }, []);

    const openEditWander = useCallback(() => {
        setEditWanderOpen(true);
    }, []);

    const closeEditWander = useCallback(() => {
        setEditWanderOpen(false);
    }, []);    


    const toggleAccordion = async () => {
        setIsOpen(prevOpen => !prevOpen);
        if (!isOpen && recordings.length === 0) {
            try {
                // Use RecordingStore to fetch recordings for the pin
                await RecordingStore.fetchRecordingsForPin(wanderData.pinKey);
                // No need to call setRecordings because RecordingStore will automatically update the recordings state
            } catch (error) {
                console.error("Failed to fetch recordings: ", error);
            }
        }
    };

    // Sort recordings by sequenceNumber before rendering
    const sortedRecordings = recordings.slice().sort((a, b) => (a.sequenceNumber || 0) - (b.sequenceNumber || 0));
    return (
        <div className="accordion">
            <div className="wander-accordion-header">
                <div className="chevron-container" onClick={toggleAccordion}>
                    <Icon
                        icon="chevron"
                        width={60}
                        height={20}
                        className={`chevron-stroke-icon ${isOpen ? 'chevron-rotated' : ''}`}
                    />
                </div>
                <a className="headingdata">{wanderData?.name}</a>
                <IconButton className="edit-container" color="primary" onClick={openEditWander}>
                    <EditSharpIcon style={{ fontSize: 40, color: 'black' }} />
                </IconButton>
            </div>
            {isOpen && (
                <div className="pin-information">
                    {loading ? (
                        <div className="loading-container">
                            <CircularProgress className="loading-container" />
                        </div>
                    ) : sortedRecordings.length === 0 ? (
                        <div className="no-recordings">Add a recording</div>
                    ) : (
                        sortedRecordings.map((recording, index) => (
                            <div className="recording-container" key={recording.key}>
                                {index === 0 && <div className="recording-heading">Walking Audio</div>}
                                <RecordingAccordion
                                    recordingKey={recording.key}
                                    pinKey={wanderData.pinKey}
                                    pinData={wanderData}
                                    pinType="geoLocation"
                                    className={`${index === 0 ? 'first-recording' : ''} ${index === sortedRecordings.length - 1 ? 'last-recording' : ''}`}
                                    sourceView="WanderView"
                                />
                                {index === sortedRecordings.length - 2 && <div className="recording-heading">Prompt Audio</div>}
                            </div>
                        ))
                    )}
                    <div className="button-container5">
                        <Button
                            className="button-add-new-recording"
                            color="primary"
                            size="medium"
                            variant="contained"
                            onClick={openRecordingEditor}
                        >
                            Add Recording
                        </Button>
                    </div>
                </div>

            )}
            {isRecordingEditorOpen && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Top right"
                    onOutsideClick={closeRecordingEditor}
                >
                    <RecordingEditor
                        onClose={closeRecordingEditor}
                        recordingKey={undefined} 
                        recording={undefined} 
                        pinKey={wanderData?.pinKey}
                        albumKey={wanderData?.albumKey}
                        pinType="geoLocation"
                        sourceView="WanderView"
                        onRecordingAdded={() => {
                            RecordingStore.fetchRecordingsForPin(wanderData?.pinKey);
                        }}
                        config={config}
                    />
                </PortalPopup>
            )}
            {isEditWanderOpen && wanderData && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Top right"
                    onOutsideClick={closeEditWander}
                >
                    <EditWanderMemoized
                        key={wanderData.wanderKey}
                        onClose={() => setEditWanderOpen(false)}
                        wanderData={wanderData}
                        onSave={updateWander}
                        wanderKey={wanderData.wanderKey}
                    />
                </PortalPopup>
            )}
        </div>
    );
});

export default WanderAccordion;