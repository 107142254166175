// transcriptionService.ts

interface TranscriptionParams {
    fileName: string;
    recordingId: string;
    transcriptionText?: string;
  }
  
  const TRANSCRIPTION_URL = 'https://europe-west2-overhear-2.cloudfunctions.net/speechToText/speech-to-text';

  const fetchWithRetry = async (url: string, options: RequestInit, retries: number = 3, delay: number = 1000): Promise<Response> => {
    for (let i = 0; i < retries; i++) {
        const response = await fetch(url, options);
        if (response.ok) {
            return response;
        }
        if (i < retries - 1) {
            await new Promise(res => setTimeout(res, delay));
        }
    }
    throw new Error(`Failed to fetch ${url} after ${retries} retries`);
};

export const initiateTranscription = async ({ fileName, recordingId, transcriptionText }: TranscriptionParams): Promise<any> => {
    try {
        console.log('Initiating transcription for:', { fileName, recordingId });
        if (!fileName) {
          throw new Error('Recording path is undefined');
        }
        const response = await fetchWithRetry(TRANSCRIPTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName, recordingId, transcriptionText }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Transcription service error:', response.status, errorText);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const data = await response.json();
        console.log('Transcription initiated successfully:', data);
        return data;
    } catch (error) {
        console.error('Error initiating transcription:', error);
        throw error;
    }
  };
