import { db } from '../firebase/firebase';
import { collection, getDocs, doc, getDoc, updateDoc, query, where, addDoc, setDoc, DocumentReference } from 'firebase/firestore';
import { IAuthor } from '../types/Author';

export const AuthorManager = {
  getAllAuthors: async (): Promise<IAuthor[]> => {
    try {
      const snapshot = await getDocs(collection(db, 'Authors'));
      return snapshot.docs.map(doc => ({ authorKey: doc.id, ...doc.data() }) as IAuthor);
    } catch (error) {
      console.log("Error fetching authors:", error);
      return [];
    }
  },

  getAuthor: async (authorKey: string): Promise<IAuthor | null> => {
    try {
      const authorDocRef = doc(db, 'Authors', authorKey);
      const docSnapshot = await getDoc(authorDocRef);
      if (docSnapshot.exists()) {
        return { authorKey: docSnapshot.id, ...docSnapshot.data() } as IAuthor;
      } else {
        console.log("No author found with the given key");
        return null;
      }
    } catch (error) {
      console.log("Error fetching author:", error);
      return null;
    }
  },

  updateAuthor: async (authorKey: string, updatedData: Partial<IAuthor>): Promise<void> => {
    try {
      console.log("AuthorManager: Attempting to update author with key:", authorKey);
      console.log("AuthorManager: Update data:", JSON.stringify(updatedData, null, 2));
      
      const authorDocRef: DocumentReference = doc(db, 'Authors', authorKey);
      console.log("AuthorManager: Author document reference created");

      // Check if the document exists before updating
      const docSnapshot = await getDoc(authorDocRef);
      if (!docSnapshot.exists()) {
        throw new Error(`Author document with key ${authorKey} does not exist`);
      }

      // Log the current document data
      console.log("AuthorManager: Current author data:", JSON.stringify(docSnapshot.data(), null, 2));

      // Remove any undefined or null values from updatedData
      const cleanedData: Partial<IAuthor> = {};
      for (const [key, value] of Object.entries(updatedData)) {
        if (value !== undefined && value !== null) {
          if (key === 'tags' && typeof value === 'object') {
            cleanedData.tags = value as { authorTags: string[] };
          } else {
            (cleanedData as any)[key] = value;
          }
        }
      }

      console.log("AuthorManager: Cleaned update data:", JSON.stringify(cleanedData, null, 2));

      // Perform the update
      await updateDoc(authorDocRef, cleanedData);
      console.log("AuthorManager: Author update successful");
    } catch (error) {
      console.error("AuthorManager: Error updating author:", error);
      if (error instanceof Error) {
        console.error("AuthorManager: Error message:", error.message);
        console.error("AuthorManager: Error stack:", error.stack);
      }
      if (error instanceof Error && 'code' in error) {
        console.error("AuthorManager: Error code:", (error as any).code);
      }
      throw error;
    }
  },
  getAuthorByName: async (name: string): Promise<IAuthor | null> => {
    try {
      const q = query(collection(db, 'Authors'), where('name', '==', name));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        return { authorKey: docSnapshot.id, ...docSnapshot.data() } as IAuthor;
      } else {
        console.log("No author found with the given name");
        return null;
      }
    } catch (error) {
      console.log("Error fetching author by name:", error);
      return null;
    }
  },

  createAuthor: async (authorKey: string, newAuthorData: Partial<IAuthor>): Promise<IAuthor> => {
    try {
      const authorDocRef = doc(db, 'Authors', authorKey);
      await setDoc(authorDocRef, newAuthorData);
      return { authorKey, ...newAuthorData } as IAuthor;
    } catch (error) {
      console.log("Error creating new author:", error);
      throw error;
    }
  },
};