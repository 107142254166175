import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RecordingEditor from '../components/RecordingEditor';
import { ProjectManager } from '../models/ProjectManager';
import './FormsView.css'
import "../components/EditRecording.css";
import { TextField } from '@mui/material';
import RecordingStore from '../stores/RecordingStore'; // Add this import

const FormsView: React.FC = observer(() => {
  const { project_id } = useParams<{ project_id: string }>();
  const [config, setConfig] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
    password: ''
  });
  const [inputPassword, setInputPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);

  const fetchConfig = async () => {
    console.log('Fetching config for project_id:', project_id);
    if (project_id) {
      const projectConfig = await ProjectManager.getProjectConfig(project_id);
      if (projectConfig) {
        setConfig(projectConfig);
      }
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [project_id]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
  };

  const handleSubmitPassword = async () => {
    setPasswordSubmitted(true);
    await fetchConfig();
    if (!isPasswordCorrect()) {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitPassword();
    }
  };

  const isPasswordCorrect = () => {
    const isCorrect = inputPassword === config.password;
    return isCorrect;
  };

  const handleClose = () => {
    // Handle closing the RecordingEditor if needed
    console.log('Recording submission completed');
  };

  // Add this function to handle recording added
  const handleRecordingAdded = () => {
    console.log('Recording added successfully');
    // You can add any additional logic here if needed
  };

  return (
    <div>
      {!passwordSubmitted || !isPasswordCorrect() ? (
        <div className='password-container'>
          <label>Enter Password to Access Form:</label>
          <TextField
            type="password"
            onChange={handlePasswordChange}
            value={inputPassword}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSubmitPassword}>Submit</button> 
        </div>
      ) : (
        <RecordingEditor
          onClose={handleClose}
          recordingKey={undefined}
          recording={undefined}
          sourceView="Form"
          config={config}
          project_id={project_id}
          onRecordingAdded={handleRecordingAdded} // Add this prop
          onSave={async (updatedRecording) => {

            console.log('Recording saved:', updatedRecording);
            await RecordingStore.addRecording(updatedRecording);
          }}
        />
      )}
    </div>
  );
});

export default FormsView;