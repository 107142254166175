import React, { FunctionComponent, useCallback, useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { handleFileUpload, constructUrl } from "../utilities";
import { RecordingManager, AuthorManager, ProjectManager, TagManager } from "../models";
import { IRecording, IRecordingWithAuthor } from "../types";
import { auth } from '../firebase/firebase';
import UserManager from "../models/UserManager";
import { ProjectContext } from '../contexts/ProjectContext';
import RecordingStore from '../stores/RecordingStore';
import QRCode from 'qrcode';
import { initiateTranscription } from '../services/transcriptionService';
import { sendEmailNotification } from '../services/emailService';
import RecordingForm from './RecordingForm';
import { handleDelete } from "../utilities/handleDelete";
import { updateTags, addTagsToNewRecording } from '../utilities/tagUpdate';
import './EditRecording.css';

type RecordingEditorProps = {
  onClose: () => void;
  recordingKey?: string;
  recording?: IRecordingWithAuthor;
  pinKey?: string;
  pinType?: string;
  albumKey?: string | null;
  sourceView: 'PinList' | 'WanderView' | 'Form' | 'SideBar';
  onRecordingAdded?: () => void;
  onSave?: (updatedRecording: IRecordingWithAuthor) => Promise<void>;
  config: {
    locationInfo: boolean;
    authorInfo: boolean;
    recordingInfo: boolean;
    uploadFields: boolean;
  };
  project_id?: string;
};

export const RecordingEditor: FunctionComponent<RecordingEditorProps> = ({
  onClose,
  recordingKey,
  recording,
  pinKey = '',
  pinType = '',
  albumKey = null,
  sourceView,
  onRecordingAdded,
  onSave,
  config,
  project_id,
}) => {
  const isEditing = !!recordingKey;

  const initialButtonState = (): 'mp3' | 'url' => {
    if (recording?.file?.audioURL) {
      return 'url';
    } else if (recording?.file?.recordingPath) {
      return 'mp3';
    }
    return 'mp3'; // Default to 'mp3' if no existing data
  };

  const [selectedButton, setSelectedButton] = useState<'mp3' | 'url'>(initialButtonState());
  const [authorDetails, setAuthorDetails] = useState({
    authorName: recording?.author?.name || '',
    authorBio: recording?.author?.bio || '',
    authorWebsite: recording?.author?.website || '',
    authorTags: recording?.author?.tags?.authorTags || [],
    authorImageUrl: recording?.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg'
  });
  const [recordingDetails, setRecordingDetails] = useState({
    recordingURL: recording?.file?.audioURL || '',
    fileSize: recording?.file?.size,
    fileName: recording?.file?.recordingPath ? recording.file.recordingPath.split('/').pop() || undefined : undefined,
    transcription: recording?.transcription || '',
    title: recording?.file?.title || '',
    description: recording?.file?.description || '',
    narrator: recording?.ownership?.recordingNarrator || '',
    subjectTags: recording?.tags?.subjectTags || [],
    genreTags: recording?.tags?.genreTags || [],
    whereQRFind: recording?.whereQRFind,
    selectedButton: initialButtonState(),
  });
  const [availableGenreTags, setAvailableGenreTags] = useState<string[]>([]);
  const [initialAuthorTags, setInitialAuthorTags] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [locationDescription, setLocationDescription] = useState('');
  const [, setQrPath] = useState<string>(recording?.qrPath || '');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [retainedAuthorDetails, setRetainedAuthorDetails] = useState<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    authorImageUrl: string;
  } | null>(null);

  const projectContext = useContext(ProjectContext);

  useEffect(() => {
    console.log('RecordingEditor - Initial recording:', recording);
    if (recording?.author) {
      setAuthorDetails({
        authorName: recording.author.name || '',
        authorBio: recording.author.bio || '',
        authorWebsite: recording.author.website || '',
        authorTags: recording.author.tags?.authorTags || [],
        authorImageUrl: recording.author.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg'
      });
      if (recording.author.tags?.authorTags) {
        const initialAuthorTags = Array.isArray(recording.author.tags.authorTags) 
          ? recording.author.tags.authorTags 
          : [];
        setInitialAuthorTags(initialAuthorTags);
        console.log('RecordingEditor - Setting initial author tags:', initialAuthorTags);
      }
    }
    if (recording?.tags) {
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
      }));
    }
    // Add this log to check the initial values
    console.log('RecordingEditor - Initial recordingDetails:', recordingDetails);
  }, [recording]);

  useEffect(() => {
    console.log("albumKey in RecordingEditor:", albumKey);
  }, [albumKey]);

  useEffect(() => {
    console.log('RecordingEditor - authorTags:', authorDetails.authorTags);
  }, [authorDetails.authorTags]);

  useEffect(() => {
    console.log('RecordingEditor - subjectTags:', recordingDetails.subjectTags);
  }, [recordingDetails.subjectTags]);

  useEffect(() => {
    console.log('RecordingEditor - genreTags:', recordingDetails.genreTags);
  }, [recordingDetails.genreTags]);

  // Log initial recording object only once
  useEffect(() => {
    console.log('RecordingEditor - Initial recording object:', recording);
  }, []);

  useEffect(() => {
    const fetchGenreTags = async () => {
      try {
        const adminTags = await TagManager.observeAdminTags();
        const fetchedGenreTags = adminTags.length > 0 ? adminTags[0].genreTags : [];
        setAvailableGenreTags(fetchedGenreTags);
      } catch (error) {
        console.error('Error fetching genre tags:', error);
      }
    };
    fetchGenreTags();
  }, []);

  const handleAuthorDetailsChange = useCallback((details: Partial<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    imageFile?: File | null;
  }>) => {
    setAuthorDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        authorImageUrl: details.imageFile ? URL.createObjectURL(details.imageFile) : prevDetails.authorImageUrl
      };
      console.log('Updated authorDetails:', newDetails);
      return newDetails;
    });
    if (details.imageFile) {
      setSelectedImageFile(details.imageFile);
    }
  }, []);

  const handleRecordingDetailsChange = useCallback((details: Partial<typeof recordingDetails> & { file?: File }) => {
    setRecordingDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        selectedButton: details.selectedButton || prevDetails.selectedButton,
      };
      console.log('Updated recordingDetails:', newDetails);
      return newDetails;
    });

    if (details.file) {
      setSelectedFile(details.file);
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        fileName: details.file?.name
      }));
    }
  }, []);

  const handleLocationDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationDescription(event.target.value);
  };

  const generateAndUploadQRCode = async (data: string): Promise<string> => {
    const qrCode = await QRCode.toDataURL(data, {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      renderer: {
        quality: 0.92
      }
    });
    return qrCode;
  };

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = async () => {
    setIsUploading(true);
    let authorUpdates: Record<string, any> = {};
    let updatedRecording: IRecording | null = null;
    try {
      console.log("RecordingEditor: Starting save process");
      let authorKey = recording?.author?.authorKey;
      let newAuthorImagePath = recording?.author?.image || '/assets/icons/user.svg';
      let newRecordingPath = '';
      let transcription = '';
      let timingData = {};
      // Check if projectContext is defined
      if (!projectContext) {
        throw new Error('RecordingEditor must be used within a ProjectProvider');
      }

      // Use project_id prop if sourceView is 'Form', otherwise use selectedProject from context
      const selectedProject = sourceView === 'Form' ? project_id : projectContext.selectedProject;

      if (!selectedProject) {
        throw new Error('No project selected');
      }

      // Check if the user is logged in
      const userId = auth.currentUser?.uid;
      let currentUserKey = null;
      if (userId) {
        const currentUser = await UserManager.getCurrentUser(userId);
        if (currentUser && currentUser.userKey) {
          currentUserKey = currentUser.userKey;
        }
      }
      // Handle author image upload if a new image is selected
      if (selectedImageFile) {
        const timestampedFileName = `${Date.now()}-${selectedImageFile.name}`;
        const setAuthorImageUrl: React.Dispatch<React.SetStateAction<string>> = (urlOrUpdater) => {
          setAuthorDetails(prev => ({
            ...prev,
            authorImageUrl: typeof urlOrUpdater === 'function' ? urlOrUpdater(prev.authorImageUrl) : urlOrUpdater
          }));
        };
        newAuthorImagePath = await handleFileUpload(selectedImageFile, setAuthorImageUrl, `images/author/`, timestampedFileName);
      }

      // Update or create author
      if (authorKey) {
        console.log("RecordingEditor: Updating existing author:", authorKey);
        authorUpdates = {}; // Initialize authorUpdates
        if (authorDetails.authorName !== recording?.author?.name) authorUpdates.name = authorDetails.authorName || null;
        if (authorDetails.authorBio !== recording?.author?.bio) authorUpdates.bio = authorDetails.authorBio || null;
        if (authorDetails.authorWebsite !== recording?.author?.website) authorUpdates.website = authorDetails.authorWebsite || null;
        if (newAuthorImagePath !== recording?.author?.image) authorUpdates.image = newAuthorImagePath || null;

        // Only update authorTags if they have changed and are not empty
        if (authorDetails.authorTags.length > 0 && authorDetails.authorTags !== recording?.author?.tags?.authorTags) {
          authorUpdates.tags = { authorTags: authorDetails.authorTags.filter(tag => tag !== undefined) }; // Filter out undefined values
        }

        // Remove any undefined values
        Object.keys(authorUpdates).forEach(key => {
          if (authorUpdates[key] === undefined) {
            delete authorUpdates[key];
          }
        });

        if (Object.keys(authorUpdates).length > 0) {
          console.log("RecordingEditor: Author updates to be applied:", JSON.stringify(authorUpdates, null, 2));
          try {
            await AuthorManager.updateAuthor(authorKey, authorUpdates);
            console.log("RecordingEditor: Author update successful");
          } catch (authorUpdateError) {
            console.error('RecordingEditor: Error updating author:', authorUpdateError);
            // Continue with the save process even if author update fails
          }
          RecordingStore.updateRecordingAuthor(recordingKey!, authorUpdates);
        } else {
          console.log("RecordingEditor: No author updates needed");
        }
      } else {
        console.log("RecordingEditor: Creating new author");
        authorKey = uuidv4();
        const newAuthorData = {
          authorKey: authorKey,
          name: authorDetails.authorName,
          bio: authorDetails.authorBio || null,
          website: authorDetails.authorWebsite || null,
          image: newAuthorImagePath || null,
          tags: { authorTags: authorDetails.authorTags.filter(tag => tag !== undefined) } // Filter out undefined values
        };

        await AuthorManager.createAuthor(authorKey, newAuthorData);
      }

      // Handle recording file upload if a new file is selected
      if (selectedFile) {
        const timestampedFileName = `${Date.now()}-${selectedFile.name}`;
        newRecordingPath = await handleFileUpload(selectedFile, () => {}, `recordings/`, timestampedFileName);
      }

      // Generate and upload QR code if pinType is 'qrCode'
      let qrCodeImagePath = '';
      if (pinType === 'qrCode') {
        qrCodeImagePath = await generateAndUploadQRCode(recordingKey || uuidv4());
        setQrPath(qrCodeImagePath);
      }

      // Prepare the recording object
      updatedRecording = {
        key: recordingKey || uuidv4(),
        albumKey: albumKey || null,
        qrPath: qrCodeImagePath || '',
        file: {
          description: recordingDetails.description || '',
          audioURL: selectedButton === 'url' ? recordingDetails.recordingURL : '',
          recordingPath: newRecordingPath || recording?.file?.recordingPath || '',
          title: recordingDetails.title || '',
          size: recordingDetails.fileSize || 0,
        },
        ownership: {
          recordingNarrator: recordingDetails.narrator || '',
          recordingAuthor: authorKey || '',
          recordingOwner: currentUserKey || '',
        },
        project: sourceView === 'WanderView' ? 'Wanders' : selectedProject,
        pinKey: pinKey || '',
        tags: {
          subjectTags: recordingDetails.subjectTags.filter(tag => tag !== undefined), // Filter out undefined values
          genreTags: recordingDetails.genreTags.filter(tag => tag !== undefined), // Filter out undefined values
        },
        whereQRFind: recordingDetails.whereQRFind || '',  // Ensure this field is not undefined
        transcription: transcription || '',
        timingData: timingData || {}
      };

      console.log("RecordingEditor: updatedRecording before save:", updatedRecording);

      // Check for undefined values in updatedRecording
      Object.keys(updatedRecording).forEach(key => {
        if ((updatedRecording as any)[key] === undefined) {
          delete (updatedRecording as any)[key];
        }
      });

      // Update or create recording
      try {
        if (isEditing) {
          await RecordingManager.updateRecording(recordingKey!, updatedRecording);
        } else {
          const key = await RecordingManager.createRecording(updatedRecording);
          if (key) {
            RecordingStore.addRecording(updatedRecording);
          } else {
            console.error('RecordingEditor: Failed to create new recording');
            throw new Error('RecordingEditor: Failed to create new recording');
          }
        }
      } catch (recordingError) {
        console.error('RecordingEditor: Error updating/creating recording:', recordingError);
        throw recordingError;
      }

      // Update tags
      try {
        // Filter out empty tags
        const filteredAuthorTags = authorDetails.authorTags.filter(tag => tag.trim() !== '');
        const filteredSubjectTags = recordingDetails.subjectTags.filter(tag => tag.trim() !== '');
        const filteredGenreTags = recordingDetails.genreTags.filter(tag => tag.trim() !== '');

        console.log('RecordingEditor: Saving tags:', {
          authorTags: filteredAuthorTags,
          subjectTags: filteredSubjectTags,
          genreTags: filteredGenreTags
        });

        if (isEditing) {
          await updateTags(
            recording!,
            filteredAuthorTags,
            filteredSubjectTags,
            filteredGenreTags,
            pinKey,
            initialAuthorTags
          );
        } else {
          // For new recordings
          if (filteredAuthorTags.length > 0 || filteredSubjectTags.length > 0 || filteredGenreTags.length > 0) {
            await addTagsToNewRecording(
              updatedRecording,
              filteredAuthorTags,
              filteredSubjectTags,
              filteredGenreTags,
              pinKey
            );
          }
        }

        // After save, update the initialAuthorTags
        setInitialAuthorTags(filteredAuthorTags);
        setAuthorDetails(prevDetails => ({
          ...prevDetails,
          authorTags: filteredAuthorTags
        }));

      } catch (tagError) {
        console.error('RecordingEditor: Error updating tags:', tagError);
        // Continue with the save process even if tag update fails
      }

      // Update project audio size
      if (recordingDetails.fileSize && selectedProject) {
        await ProjectManager.updateProjectAudioSize(selectedProject, recordingDetails.fileSize);
      }

      // Send email notification if sourceView is 'Form'
      if (sourceView === 'Form' && selectedProject) {
        await sendEmailNotification(locationDescription, selectedProject);
      }

      if (onRecordingAdded) {
        onRecordingAdded();
      }

      try {
        if (onRecordingAdded) {
          onRecordingAdded();
        }

        if (onSave && isEditing) {
          await onSave(updatedRecording);
        }
      } catch (callbackError) {
        console.error('RecordingEditor: Error in save callbacks:', callbackError);
        // Don't throw this error, as the main save operation has completed
      }

      setSaveSuccess(true);
      onClose();

      // Initiate transcription if necessary (after closing the editor)
      if (newRecordingPath) {
        initiateTranscription({ fileName: newRecordingPath, recordingId: recordingKey || updatedRecording.key, transcriptionText: transcription })
          .catch(error => {
            console.error('RecordingEditor: Error initiating transcription:', error);
          });
      }

      console.log("RecordingEditor: Save process completed successfully");
    } catch (error) {
      console.error('RecordingEditor: Error in save process:', error);
      if (error instanceof Error) {
        console.error('RecordingEditor: Error details:', error.message);
        console.error('RecordingEditor: Error stack:', error.stack);

        // Additional logging to identify undefined values
        if (error.message.includes('Unsupported field value: undefined')) {
          console.error('RecordingEditor: Author updates:', JSON.stringify(authorUpdates, null, 2));
          console.error('RecordingEditor: Current author data:', JSON.stringify(recording?.author, null, 2));
          console.error('RecordingEditor: Updated recording object:', JSON.stringify(updatedRecording, null, 2));
        }
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteRecording = async () => {
    if (isEditing && recordingKey) {
      await handleDelete(recordingKey, pinKey, onClose);
    }
  };

  const resetForm = useCallback(() => {
    setSelectedButton('mp3'); 
    setRecordingDetails({
      recordingURL: '',
      fileSize: undefined,
      fileName: undefined,
      transcription: '',
      title: '',
      description: '',
      narrator: '',
      subjectTags: [],
      genreTags: [],
      whereQRFind: '',
      selectedButton: 'mp3',
    });
    setQrPath('');
    setLocationDescription('');
    if (!isEditing) {
      setAuthorDetails({
        authorName: '',
        authorBio: '',
        authorWebsite: '',
        authorTags: [],
        authorImageUrl: '/assets/icons/user.svg'
      });
    }
    if (retainedAuthorDetails) {
      setAuthorDetails(retainedAuthorDetails);
    }
  }, [isEditing, retainedAuthorDetails]);

  const handleRetainArtistDetails = useCallback((retain: boolean) => {
    if (retain) {
      setRetainedAuthorDetails(authorDetails);
    } else {
      setRetainedAuthorDetails(null);
    }
    setSaveSuccess(false);
  }, [authorDetails]);

  useEffect(() => {
    if (!saveSuccess) {
      resetForm();
    }
  }, [saveSuccess, resetForm]);

  const setAuthorTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorDetails(prevDetails => ({
      ...prevDetails,
      authorTags: typeof newTags === 'function' ? newTags(prevDetails.authorTags) : newTags
    }));
  }, []);

  const setSubjectTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      subjectTags: typeof newTags === 'function' ? newTags(prevDetails.subjectTags) : newTags
    }));
  }, []);

  const setGenreTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      genreTags: typeof newTags === 'function' ? newTags(prevDetails.genreTags) : newTags
    }));
  }, []);

  useEffect(() => {
    if (recording) {
      setAuthorDetails({
        authorName: recording.author?.name || '',
        authorBio: recording.author?.bio || '',
        authorWebsite: recording.author?.website || '',
        authorTags: recording.author?.tags.authorTags || [],
        authorImageUrl: recording.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg'
      });
      setRecordingDetails({
        recordingURL: recording.file?.audioURL || '',
        fileSize: recording.file?.size,
        fileName: recording.file?.recordingPath,
        transcription: recording.transcription || '',
        title: recording.file?.title || '',
        description: recording.file?.description || '',
        narrator: recording.ownership?.recordingNarrator || '',
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
        whereQRFind: recording.whereQRFind || '',
        selectedButton: recording.file?.audioURL ? 'url' : 'mp3',
      });
      setInitialAuthorTags(recording.author?.tags.authorTags || []);
    }
  }, [recording]);

  return (
    <div className={sourceView === 'WanderView' ? "edit-wander" : (sourceView === 'Form' ? "edit-recording form-view" : "edit-recording")}>
      <h1 className="edit-recording1">{isEditing ? 'Edit Recording' : 'Add Recording'}</h1>
      <RecordingForm
        sourceView={sourceView}
        config={config}
        authorDetails={authorDetails}
        recordingDetails={recordingDetails}
        locationDescription={locationDescription}
        pinType={pinType}
        isUploading={isUploading}
        onAuthorDetailsChange={handleAuthorDetailsChange}
        onRecordingDetailsChange={handleRecordingDetailsChange}
        onLocationDescriptionChange={handleLocationDescriptionChange}
        onCancel={handleCancel}
        onSave={handleSave}
        onDelete={handleDeleteRecording}
        showDelete={isEditing}
        saveSuccess={saveSuccess}
        setSaveSuccess={setSaveSuccess}
        resetForm={resetForm}
        setRetainArtistDetails={handleRetainArtistDetails}
        availableGenreTags={availableGenreTags}
        setAuthorTags={setAuthorTags}
        setSubjectTags={setSubjectTags}
        setGenreTags={setGenreTags}
      />
    </div>
  );
};

export default RecordingEditor;


