import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { ProjectManager } from '../models';
import { Icon } from '../components/Icon';
import './InviteLanding.css';

const TestInviteLanding: React.FC = () => {
  const { inviteId } = useParams<{ inviteId: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [project_id, setProject_id] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        if (inviteId === 'test-id') {
          // For testing purposes, set dummy project data
          setProject_id('test-project-id');
          setProjectName('Test Project');
        } else {
          const response = await fetch(`https://invite-service-dot-overhear-2.uc.r.appspot.com/api/invite/${inviteId}`);
          const data = await response.json();
          if (data.success) {
            setProject_id(data.project_id);
            // Fetch project details using ProjectManager
            const projectDetails = await ProjectManager.getProject(data.project_id);
            setProjectName(projectDetails!.projectName!);
          } else {
            setError(data.message || 'Invalid invite link');
          }
        }
      } catch (error) {
        setError('Failed to fetch project data');
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [inviteId]);

  const handleAuthentication = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSignUp && password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      
      if (project_id) {
        await ProjectManager.addUserToProject(userCredential.user.uid, project_id);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setError(isSignUp ? 'Failed to create account' : 'Failed to sign in');
    }
  };

  if (isAuthenticated) {
    return (
      <div className="invite-landing-container">
        <div className="invite-landing-content">
          <h1>Download the App</h1>
          <p>To view the project you've been invited to, please download our app:</p>
          <div className="app-download">
            <a href="https://apps.apple.com/your-app-store-link" target="_blank" rel="noopener noreferrer">
              <img src="/assets/overhear-assets/images/App store.png" alt="Download on the App Store" />
            </a>
            <a href="https://play.google.com/store/your-play-store-link" target="_blank" rel="noopener noreferrer">
              <img src="/assets/overhear-assets/images/Play store.png" alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="invite-landing-container">
      <div className="invite-landing-content">
        <Icon icon="overhearlight" size={100} className="overhear-logo" />
        <h1>You've been invited!</h1>
        <p>You've been invited to OVERHEAR this project. Please sign in or sign up to continue.</p>
        {projectName && <p>Project: {projectName}</p>}
        
        <form onSubmit={handleAuthentication}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          {isSignUp && (
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              required
            />
          )}
          <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
        </form>

        <div className="toggle-auth">
          <p>
            {isSignUp ? 'Already have an account? ' : "Don't have an account? "}
            <button onClick={() => setIsSignUp(!isSignUp)}>
              {isSignUp ? 'Sign In' : 'Sign Up'}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestInviteLanding;
