import React, { FunctionComponent, useEffect, useState, useMemo, useRef, ChangeEvent } from "react";
import AuthorInformationFields from "./AuthorInformationFields";
import RecordingUploadFields from "./RecordingUploadFields";
import RecordingInformationFields from "./RecordingInformationFields";
import FormButtons from './FormButtons';
import { TextField } from "@mui/material";

type RecordingFormProps = {
  sourceView: 'PinList' | 'WanderView' | 'Form' | 'SideBar';
  config: {
    locationInfo: boolean;
    authorInfo: boolean;
    recordingInfo: boolean;
    uploadFields: boolean;
  };
  authorDetails: {
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    authorImageUrl: string;
  };
  recordingDetails: {
    recordingURL: string;
    fileSize: number | undefined;
    fileName: string | undefined;
    transcription: string;
    title: string;
    description: string;
    narrator: string;
    subjectTags: string[];
    genreTags: string[];
    whereQRFind?: string;
    selectedButton: 'mp3' | 'url';
  };
  locationDescription: string;
  pinType?: string;
  isUploading: boolean;
  onAuthorDetailsChange: (details: Partial<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    imageFile?: File | null;
  }>) => void;
  onRecordingDetailsChange: (details: Partial<RecordingFormProps['recordingDetails']>) => void;
  onLocationDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete: () => void;
  showDelete: boolean;
  saveSuccess: boolean;
  setSaveSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm: () => void;
  setRetainArtistDetails: (retain: boolean) => void;
  availableGenreTags: string[];
  setAuthorTags: React.Dispatch<React.SetStateAction<string[]>>;
  setSubjectTags: React.Dispatch<React.SetStateAction<string[]>>;
  setGenreTags: React.Dispatch<React.SetStateAction<string[]>>;
};

const RecordingForm: FunctionComponent<RecordingFormProps> = ({
  sourceView,
  config,
  authorDetails,
  recordingDetails,
  locationDescription,
  pinType,
  isUploading,
  onAuthorDetailsChange,
  onRecordingDetailsChange,
  onLocationDescriptionChange,
  onCancel,
  onSave,
  onDelete,
  showDelete,
  saveSuccess,
  setSaveSuccess,
  resetForm,
  setRetainArtistDetails,
  availableGenreTags,
  setAuthorTags,
  setSubjectTags,
  setGenreTags,
}) => {
  const prevRecordingDetailsRef = useRef(recordingDetails);

  useEffect(() => {
    if (JSON.stringify(prevRecordingDetailsRef.current) !== JSON.stringify(recordingDetails)) {
      console.log('RecordingForm - Received new recordingDetails:', recordingDetails);
      prevRecordingDetailsRef.current = recordingDetails;
    }
  }, [recordingDetails]);

  const prevGenreTagsRef = useRef<string[]>(recordingDetails.genreTags);

  useEffect(() => {
    if (JSON.stringify(prevGenreTagsRef.current) !== JSON.stringify(recordingDetails.genreTags)) {
      console.log('RecordingForm received new genre tags:', recordingDetails.genreTags);
      prevGenreTagsRef.current = recordingDetails.genreTags;
    }
  }, [recordingDetails.genreTags]);

  useEffect(() => {
    console.log('RecordingForm - Received authorDetails:', authorDetails);
    console.log('RecordingForm - Received recordingDetails:', recordingDetails);
  }, [authorDetails, recordingDetails]);

  const handleAuthorTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorTags(newTags);
  };

  const handleSubjectTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setSubjectTags(newTags);
  };

  const handleGenreTagsChange = (newTags: string[] | ((prevTags: string[]) => string[])) => {
    setGenreTags(newTags);
  };

  const handleSave = () => {
    console.log("RecordingForm: Save button clicked");
    onSave();
  };

  useEffect(() => {
    console.log("RecordingForm: Component mounted or updated");
    console.log("RecordingForm: Current authorDetails:", JSON.stringify(authorDetails, null, 2));
    console.log("RecordingForm: Current recordingDetails:", JSON.stringify(recordingDetails, null, 2));
  }, [authorDetails, recordingDetails]);

  const memoizedAuthorDetails = useMemo(() => authorDetails, [
    authorDetails.authorName,
    authorDetails.authorBio,
    authorDetails.authorWebsite,
    authorDetails.authorTags.join(','),
    authorDetails.authorImageUrl
  ]);

  const handleLocationDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    onLocationDescriptionChange(event);
  };

  return (
    <div className={sourceView === 'WanderView' ? "edit-wander" : (sourceView === 'Form' ? "edit-recording form-view" : "edit-recording")}>
      {saveSuccess ? (
        <div className="thank-you-message">
          <p>Thank you for your submission!</p>
          <p>Please close this screen or choose an option below:</p>
          <button onClick={() => { resetForm(); setSaveSuccess(false); setRetainArtistDetails(false); }}>Submit Another</button>
          <button onClick={() => { setRetainArtistDetails(true); setSaveSuccess(false); }}>Submit Another with Same Artist Details</button>
        </div>
      ) : (
        <>
          {sourceView === 'Form' && config.locationInfo && (
            <div>
              <a className="recording-information1" style={{ marginBottom: 15 }}>Location Information:</a>
              <TextField
                className="input-container"
                label="Location Address/Postcode/Coordinates"
                variant="filled"
                type="text"
                value={locationDescription}
                onChange={handleLocationDescriptionChange}
              />
            </div>
          )}
          {sourceView !== 'WanderView' && config.authorInfo && (
            <div>
              <AuthorInformationFields
                initialAuthorName={authorDetails.authorName}
                initialAuthorBio={authorDetails.authorBio}
                initialAuthorWebsite={authorDetails.authorWebsite}
                initialAuthorTags={authorDetails.authorTags}
                initialAuthorImageUrl={authorDetails.authorImageUrl}
                onAuthorDetailsChange={onAuthorDetailsChange}
                setAuthorTags={handleAuthorTagsChange}
              />
            </div>
          )}
          <div className="recording-information-container">
            <a className="recording-information1">Recording Information</a>
            {config.uploadFields && (
              <RecordingUploadFields
                initialRecordingURL={recordingDetails.recordingURL}
                initialFileSize={recordingDetails.fileSize}
                initialFileName={recordingDetails.fileName}
                initialTranscription={recordingDetails.transcription}
                onRecordingDetailsChange={onRecordingDetailsChange}
                uploadPath="recordings/"
                showTranscription={true}
                selectedButton={recordingDetails.selectedButton}
              />
            )}
            {config.recordingInfo && (
              <div>
                <RecordingInformationFields
                  onRecordingInfoChange={onRecordingDetailsChange}
                  pinType={pinType}
                  initialTitle={recordingDetails.title}
                  initialDescription={recordingDetails.description}
                  initialNarrator={recordingDetails.narrator}
                  initialWhereQRCode={recordingDetails.whereQRFind}
                  subjectTags={recordingDetails.subjectTags}
                  genreTags={recordingDetails.genreTags}
                  availableGenreTags={availableGenreTags}
                  setSubjectTags={handleSubjectTagsChange}
                  setGenreTags={handleGenreTagsChange}
                />
              </div>
            )}
          </div>
          <FormButtons
            onCancel={onCancel}
            onSave={handleSave}
            onDelete={onDelete}
            isUploading={isUploading}
            showDelete={showDelete}
          />
        </>
      )}
    </div>
  );
};

export default RecordingForm;