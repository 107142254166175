import { db } from '../firebase/firebase'; 
import { doc, getDoc } from 'firebase/firestore';
import { User } from '../types';


export const UserManager = {
  /**
   * Fetches the current user's data from Firestore.
   * The userId should be obtained from the AuthenticationStore after successful authentication.
   * @param userId The UID of the user, as obtained from Firebase Authentication.
   * @returns A Promise that resolves to the User data or null if not found.
   */
  getCurrentUser: async (userId: string): Promise<User | null> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.log("No user found for ID:", userId); // Log if no user is found
        return null;
      }
  
      const userData = userDoc.data();
  
      return { userKey: userDoc.id, ...userData } as User;
    } catch (error) {
      return null;
    }
  },
 
};


export default UserManager;