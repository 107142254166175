import { v4 as uuidv4 } from 'uuid';
import { FunctionComponent, useCallback, useState } from "react";
import {
  TextField,
  Button
} from "@mui/material";
import "./InputForms.css";
import { AlbumManager } from "../models";
import { IWanderPin, Album } from "../types";
import { handleFileUpload } from "../utilities";
import WanderStore from "../stores/WanderStore";


type CreateWandersProps = {
  onClose: () => void;
  onSave?: (newWander: IWanderPin) => void;
};

export const CreateWanders: FunctionComponent<CreateWandersProps> = ({ onClose, onSave }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [wanderIconUrl, setWanderIconUrl] = useState<string>('/default-wander-icon.png');
  const [name, setWanderName] = useState<string>('');
  const [wanderDescription, setWanderDescription] = useState<string>('');
 
  const [isSaving, setIsSaving] = useState(false);

  const onCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleWanderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWanderName(event.target.value);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const generatedWanderId = uuidv4();
    const generatedAlbumId = uuidv4();

    const newAlbum: Album = {
      id: generatedAlbumId,
      albumKey: generatedAlbumId,
      name: 'New Album',
      projectKey: null,
      userKey: null,
    };

    try {
      await AlbumManager.createAlbum(newAlbum);
    } catch (error) {
      console.error('Error creating album:', error);
      return;
    }

    let nextWanderOrderNo = 1;
    WanderStore.wanders.forEach((wander) => {
      if (wander.wanderOrderNo && wander.wanderOrderNo >= nextWanderOrderNo) {
        nextWanderOrderNo = wander.wanderOrderNo + 1;
      }
    });

    const newWander: IWanderPin = {
      wanderKey: generatedWanderId,
      pinKey: generatedWanderId,
      name: name,
      pinDescription: wanderDescription,
      albumKey: generatedAlbumId,
      pinType: 'geoLocation',
      wanderOrderNo: nextWanderOrderNo
    };

    try {
      await WanderStore.createWander(newWander);

      if (onSave) {
        onSave(newWander);
      }

      setIsSaving(false);
      onClose();
    } catch (error) {
      console.error('Error creating wander:', error);
      setIsSaving(false);
    }
  };

  return (
    <div className="edit-wander">
      <h1 className="heading1">Create Wander</h1>
      <TextField
        className="input-container"
        color="primary"
        label="Name"
        size="medium"
        variant="filled"
        type="text"
        value={name}
        onChange={handleWanderNameChange}
      />
      <div className="buttons">
        <button className="button-action-cancel" onClick={onCancelClick}>
          <b className="cancel-text">Cancel</b>
        </button>
        <button className="button-action-save" onClick={handleSave}>
          <b className="save-text">Save</b>
        </button>
      </div>
    </div>
  );
};

export default CreateWanders;