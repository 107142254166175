import { ProjectContext } from '../contexts/ProjectContext';

export const sendEmailNotification = async (locationDesc: string, project_id?: string) => {
  if (!project_id) {
    console.error('Cannot send email notification: project_id is undefined');
    return;
  }

  console.log('Sending email with Location Description:', locationDesc);  // Log the location description being sent
  const response = await fetch('https://email-server-dot-overhear-2.uc.r.appspot.com/api/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: 'hello@theoverhear.app',
      subject: 'FormsView Submitted',
      text: `The form has been submitted with the following details:
      - Project ID: ${project_id}
      - Location Description: ${locationDesc}`  // Use the passed locationDesc
    }),
  });

  if (response.ok) {
    console.log('Email sent successfully');
  } else {
    console.error('Failed to send email');
  }
};