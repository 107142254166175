// src/components/FormLogin.tsx

import { FunctionComponent, useEffect, useState  } from "react";
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from "react-router-dom";
import "./FormLogin.css";
import { signIn } from '../services/authService';
import { ProjectManager } from "../models";
import { IProject } from "../types";
import { db, auth } from '../firebase/firebase'; 
import { doc, getDoc } from "firebase/firestore";
import { SelectChangeEvent } from '@mui/material/Select';
import { useProjectContext } from '../contexts/ProjectContext';
import UserManager from "../models/UserManager";
import userStore from '../stores/UserStore'; // Import userStore
import { sendPasswordResetEmail } from "firebase/auth";

interface FormLoginProps {
  onToggleForm: () => void;
  isProjectAdmin: boolean;
}

const FormLogin: FunctionComponent<FormLoginProps> = ({ onToggleForm, isProjectAdmin }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { setSelectedProject: setGlobalSelectedProject } = useProjectContext();

  // Add a state to track the active button
  const [activeButton, setActiveButton] = useState<'superAdmin' | 'projectAdmin'>('projectAdmin');
  // Update the onProjectAdminButtonClick to handle both buttons
  const onButtonClick = (buttonType: 'superAdmin' | 'projectAdmin') => {
    setActiveButton(buttonType);
    onToggleForm();
  };

  const handleProjectChange = (event: SelectChangeEvent<string>) => {
    setSelectedProject(event.target.value);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const projects = await ProjectManager.observeProjects();
      setProjects(projects);
    };

    fetchProjects();
  }, []);

  const handleForgotPassword = async (email: string) => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email); // Replace 'auth' with your Firebase Auth instance
      alert('Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email. Please try again.');
    }
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signIn(email, password);
      const user = userCredential.user;
      if (user) {
        const userId = user.uid;
  
        if (activeButton === 'superAdmin') {
          // Fetch the super admin user keys from the SuperAdmins collection
          const superAdminRef = doc(db, 'SuperAdmins', 'ids');
          const superAdminSnap = await getDoc(superAdminRef);
  
          if (superAdminSnap.exists() && superAdminSnap.data().userKeys) {
            const userKeys = superAdminSnap.data().userKeys as string[];
            if (userKeys.includes(userId)) {
              // Set the user role in userStore
              userStore.setUser({ userId: user.uid, userRole: 'superAdmin' });
              navigate('/pin-list-view');
            } else {
              console.warn('User ID not found in SuperAdmins user keys.');
              alert('You are not a SuperAdmin.');
            }
          } else {
            console.error('SuperAdmins document does not exist or userKeys array is missing.');
            alert('SuperAdmins data not found.');
          }
        } else if (activeButton === 'projectAdmin') {
          // Fetch the current user's data using the UserManager
          const currentUser = await UserManager.getCurrentUser(userId);
  
          if (currentUser && currentUser.userKey) {
            const userKey = currentUser.userKey;
            const projectRef = doc(db, 'Projects', selectedProject);
            const projectSnap = await getDoc(projectRef);
  
            if (projectSnap.exists()) {
              const projectData = projectSnap.data() as IProject;
              if (projectData.projectOwnerUserKey === userKey) {
                // Set the user role in userStore
                userStore.setUser({ userId: user.uid, userRole: 'projectAdmin' });
                setGlobalSelectedProject(selectedProject);
                navigate('/map-view');
              } else {
                alert('You are not the owner of the selected project.');
              }
            } else {
            }
          } else {
          }
        }
      } else {
        console.error('User is not authenticated after signIn call.');
        alert('User is not authenticated. Please check your email and password.');
      }
    } catch (error) {
      console.error('Error in handleLogin:', error);
      if ((error as { code?: string }).code === 'auth/wrong-password') {
        alert('The password is incorrect.');
      } else {
        alert('An error occurred during login. Please try again.');
      }
    }
  };

  return (
    <div className="login-form">
      <div className="buttons8">
        <button
          className={`superadmin-button ${activeButton === 'superAdmin' ? 'active' : ''}`}
          onClick={() => onButtonClick('superAdmin')}
        >
          <b className={`superadmin-text ${activeButton === 'superAdmin' ? 'active-text' : ''}`}>Super Admin</b>
        </button>
        <button
          className={`projectadmin-button ${activeButton === 'projectAdmin' ? 'active' : ''}`}
          onClick={() => onButtonClick('projectAdmin')}
        >
          <b className={`projectadmin-text ${activeButton === 'projectAdmin' ? 'active-text' : ''}`}>Project Admin</b>
        </button>
      </div>
      {activeButton === 'projectAdmin' && (
      <Select 
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Select Your Project"
        value={selectedProject}
        onChange={handleProjectChange}
      >
        {projects.map((project) => (
          <MenuItem 
            key={project.project_id} 
            value={project.project_id}
          >
          {project.projectName}
          
          </MenuItem>
        ))}
      </Select>
       )}
      <TextField
          sx={{
            backgroundColor: "var(--color-lightsteelblue)",
            alignSelf: "stretch",
            borderRadius: "var(--br-8xs)",
            padding: "0 var(--padding-2xl)",
            boxSizing: "border-box",
            '& .MuiOutlinedInput-root': {
              fontSize: "var(--font-size-base)",
              fontFamily: "var(--font-manrope)",
              border: 0,
              fontWeight: 500,
              height: "50px",
              display: "flex",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
        placeholder="Enter your email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
      sx={{
        backgroundColor: "var(--color-lightsteelblue)",
        alignSelf: "stretch",
        borderRadius: "var(--br-8xs)",
        padding: "0 var(--padding-2xl)",
        boxSizing: "border-box",
        '& .MuiOutlinedInput-root': {
          fontSize: "var(--font-size-base)",
          fontFamily: "var(--font-manrope)",
          border: 0,
          fontWeight: 500,
          height: "50px",
          display: "flex",
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none', 
        },
      }}
        placeholder="Enter your password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <a className="forgot-password-container" onClick={() => handleForgotPassword(email)}>
        <b className="forgot-password">Forgot password?</b>
      </a>
      <button className="login-button" onClick={handleLogin}>
        <div className="login-text">Log in</div>
      </button>
    </div>
  );
};

export default FormLogin;
