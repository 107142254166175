import { db } from '../firebase/firebase';
import { collection, getDocs, getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { Album } from '../types';

// Define TypeScript interfaces for your album and other types as needed

export const AlbumManager = {
  getAllAlbums: async (): Promise<Album[]> => {
    try {
      const querySnapshot = await getDocs(collection(db, 'albums'));
      const albums: Album[] = [];
      querySnapshot.forEach((doc) => {
        const album = { albumKey: doc.id, ...doc.data() } as Album;
        albums.push(album);
      });
      return albums;
    } catch (error) {
      console.log("Error getting albums:", error);
      return [];
    }
  },

  getAlbum: async (albumKey: string): Promise<Album | null> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      const albumDoc = await getDoc(albumDocRef);
      if (!albumDoc.exists()) {
        return null;
      }
      return { albumKey: albumDoc.id, ...albumDoc.data() } as Album;
    } catch (error) {
      console.log("Error fetching album:", error);
      return null;
    }
  },

  removeAlbum: async (albumKey: string): Promise<void> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      await deleteDoc(albumDocRef);
    } catch (error) {
      console.error("Error removing album:", error);
      throw error;
    }
  },

  getAlbumByPinKey: async (albumKey: string): Promise<Album | null> => {
    try {
      const albumDocRef = doc(db, 'Albums', albumKey);
      const albumDoc = await getDoc(albumDocRef);
      if (!albumDoc.exists()) {
        return null;
      }
      return { albumKey: albumDoc.id, ...albumDoc.data() } as Album;
    } catch (error) {
      console.error("Error fetching album by album key:", error);
      return null;
    }
  },

  createAlbum: async (newAlbum: Album): Promise<Album> => {
    try {
      const albumDocRef = doc(db, 'Albums', newAlbum.id);
      await setDoc(albumDocRef, newAlbum);
      return { ...newAlbum, id: albumDocRef.id }; // Ensure to return the new album object
    } catch (error) {
      console.error("Error creating album:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  },
};

export default AlbumManager;
